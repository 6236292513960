export const TipoProductoId = {
	API: 1,
	REPORTE: 2,
	PACK_REPORTES: 3,
	PACK_APIS: 4
}

export const TipoDocumentoId = {
	CONTRATO_PRODUCTO: 1,
	FOLLETO_PRODUCTO: 2,
	DOCUMENTACION: 3,
}