import React from 'react'
import Headers from '../layout/Header';
import ContentApis from './ContentApis';
import FooterLanding from '../layout/Footer';
import { useTranslation } from 'react-i18next';


const NuestrasApis = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='catalogo imagen-mobile'>
      <Headers />
      <ContentApis t={t} i18n={i18n} />
      <FooterLanding />
    </div>
  )
}

export default NuestrasApis