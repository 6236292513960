import React, { useLayoutEffect, useRef, useState } from 'react';
import { Col, Row, Typography, Card, Carousel, Button, Flex, Spin } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons'
import { useResizeDetector } from 'react-resize-detector';

import inscripcion from '../../assets/images/inscripcion.png';
import apis from '../../assets/images/apis.png';
import request from '../../assets/images/request.png';
import lupa from '../../assets/images/analisis.png'
import tablet from '../../assets/images/registro.png'
import key from '../../assets/images/key.png'
import mano from '../../assets/images/engranaje.png'
import './NuestrasApis.scss';
import { Link, useNavigate } from 'react-router-dom';
import { getDocumento, getGuiaInicio } from '../../services/producto';
import { saveAs } from 'file-saver'
import { TipoDocumentoId } from '../../enum/enums'
import parse from 'html-react-parser';

const typeDefs = require('../../typeDefs')
const { Title, Text } = Typography;

/**
 * 
 * @param {{t:any,i18n:any productosApi:Array.<typeDefs.ProductoPackApiDto>}} props 
 * @returns 
 */
function CardsCarousel({ t, i18n, productosApi }) {
  const idDocumentoApi = 1
  const navigate = useNavigate()
  const [currentCard, setCurrentCard] = useState(0);
  const carouselRef = React.createRef();
  const [cardMaxHeight, setMaxCardHeight] = useState(0)
  const [heightsCards, setHeightsCards] = useState([])
  const [isLoadingCarrusel, setIsLoadingCarrusel] = useState(true)
  const [esPrimeraCarga, setEsPrimeraCarga] = useState(true)

  const [cardHeight, setCardHeight] = useState(360);

  const goToSlide = (slideToGo) => {
    setCurrentCard(slideToGo)
    carouselRef.current.goTo(slideToGo)

    const height = heightsCards.filter(x => x.index === slideToGo)[0].heightContainer + 'px'
    const root = document.querySelector("#carrousel-como-funciona")
    root?.style.setProperty('max-height', height)
  };

  const onRedirect = () => {
    window.scrollTo(0, 0)
  }

  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  }

  const customSort = (a, b) => {
    // Crea un mapa que asigne los códigos de producto a su orden deseado
    const orderMap = {
      "api-renta-variable-rv": 1,
      "api-renta-fija-rf": 2,
      "api-intermediacion-financiera": 3,
    };

    // Usa el mapa para obtener el orden de los productos y compara sus posiciones
    return orderMap[a.CodigoProducto] - orderMap[b.CodigoProducto];
  };

  const onResize = () => {
    if (!isLoadingCarrusel && !esPrimeraCarga) {
      recalcularAlturas()
    }
  }

  const { width, height, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100,
    onResize
  });

  const recalcularAlturas = () => {
    if (heightsCards?.length > 0) {
      setMaxCardHeight(0)
      setIsLoadingCarrusel(true)
    }
  }

  useLayoutEffect(() => {
    recalcularAlturas()
  }, [t, i18n]);

  useLayoutEffect(() => {

    // ref: https://github.com/kenwheeler/slick/issues/1803
    if (!cardMaxHeight || cardMaxHeight === 0) {
      const arr = []
      let maxHeightAux = null;
      for (let index = 0; index < cardsCarrousel.length; index++) {
        const card = cardsCarrousel[index];
        // console.log("card.elementRef", card.elementRef)
        if (card.elementRef.current) {
          arr.push({
            width: card.elementRef.current.offsetWidth,
            height: card.elementRef.current.offsetHeight,
            heightContainer: card.elementRef.current.offsetHeight + 230, //ver si puedo obtener el alto pantall y le sumo
            index: index
          })
          if (card.elementRef.current.offsetHeight > maxHeightAux) {
            maxHeightAux = card.elementRef.current.offsetHeight
          }
        }
      }
      setHeightsCards(arr)
      setMaxCardHeight(maxHeightAux + 5)//agrego 5 ya que si no, no se ve borde de card de documentacion
      // setEsPrimeraCarga(false)

      const height = arr[currentCard].heightContainer + 'px'
      const root = document.querySelector("#carrousel-como-funciona")
      const containerRoot = document.getElementsByClassName("slick-list")

      root?.style.setProperty('max-height', height)
      containerRoot[0]?.style?.setProperty('height', `${maxHeightAux + 5}px`)

      setTimeout(() => {
        setIsLoadingCarrusel(false)
      }, 200);

      setTimeout(() => {
        setEsPrimeraCarga(false)
      }, 1000);

    }
  }, [t, i18n, cardMaxHeight]);


  const handleClick = () => {
    window.location.hash = '#contacto';
  };

  /**
   * 
   * @param {typeDefs.ProductoApiDto|typeDefs.ProductoPackApiDto} item 
   * @param {string} atribute 
   */
  const getTextoIdioma = (item, atributo) => {

    if (item && item.IdIdiomaTraducir && item.IdIdiomaTraducir.length > 0) {
      const idIdiomaTraducirAux = i18n.language === "es" ? 1 : 2
      const traduccion = item.IdIdiomaTraducir.find(x => x.IdIdiomaTraducir === idIdiomaTraducirAux)
      if (traduccion) {
        return traduccion[atributo]
      }
    }
    return ""
  }


  /**
   * Realiza la descarga del documento de un producto.
   * @param {number} idProducto - identificador del producto
   * @param {number} idTipoDocumento - Identificador del tipo de documento
   */
  const onDownloadDocumento = async (idProducto, idTipoDocumento) => {

    try {
      const docRes = await getDocumento(idProducto, idTipoDocumento)
      if (docRes) {
        const blobFile = new Blob(
          [docRes.buffer],
          { type: docRes.contentType }
        )
        saveAs(blobFile, docRes.fileName)
      } else {
        console.log("Respuesta nula")
      }
    } catch (error) {
      console.error("Error al descargar documento")
      console.error(error)

    } finally {
    }

  }

  const onDownloadGuiaInicio = async () => {
    try {
      const docRes = await getGuiaInicio()
      if (docRes) {
        const blobFile = new Blob(
          [docRes.buffer],
          { type: docRes.contentType }
        )
        saveAs(blobFile, docRes.fileName)
      } else {
        console.log("Respuesta nula")
      }
    } catch (error) {
      console.error("Error al descargar documento")
      console.error(error)

    } finally {
    }

  }


  const cardsCarrousel = [
    {
      elementRef: useRef(),
      id: 0,
      element: (paramRef, paramStyle, height) =>
        <Card ref={paramRef}
          className='card-carrousel'
          style={{ ...paramStyle, height }}
        >
          <img src={lupa} alt='inscripcion' style={{ width: 150, height: 150 }} />
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>
            <Title level={4} style={{ color: '#fff', textAlign: 'left', fontSize: 30, marginTop: 5 }}>{t('how_does_it_work_card1_title')}</Title>
            <Text style={{ color: '#fff', textAlign: 'left', fontSize: 15 }}>{t('how_does_it_work_card1_description')}<Link style={{ color: 'rgb(7, 253, 235)', textDecoration: 'underline' }} to="/nuestras-apis">{t('how_does_it_work_card1_description_link')}</Link>{t('how_does_it_work_card1_description_2')}</Text>
          </div>
        </Card>,
    },
    {
      id: 1,
      element: (paramRef, paramStyle, height) =>
        <Card ref={paramRef}
          className='card-carrousel'
          style={{ ...paramStyle, height }}
        >
          <img src={tablet} alt='inscripcion' style={{ width: 150, height: 150 }} />
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>
            <Title level={4} style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>{t('how_does_it_work_card2_title')}</Title>
            <Text style={{ color: '#fff', fontSize: 15 }}>{t('how_does_it_work_card2_description')}</Text>
          </div>
        </Card>,
      elementRef: useRef()
    },
    {
      id: 2,
      element: (paramRef, paramStyle, height) =>
        <Card ref={paramRef}
          className='card-carrousel'
          style={{ ...paramStyle, height }}
        >
          <img src={request} alt='inscripcion' style={{ width: 150, height: 150 }} />
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>
            <Title level={4} style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>{t('how_does_it_work_card3_title')}</Title>
            <Text style={{ color: '#fff', fontSize: 15 }}>{t('how_does_it_work_card3_description')}</Text>
          </div>
        </Card>,
      elementRef: useRef()
    },
    {
      id: 3,
      element: (paramRef, paramStyle, height) =>
        <Card ref={paramRef}
          className='card-carrousel'
          style={{ ...paramStyle, height }}
        >
          <img src={mano} alt='inscripcion' style={{ width: 150, height: 150 }} />
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>
            <Title level={4} style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>{t('how_does_it_work_card4_title')}</Title>
            <Text style={{ color: '#fff', fontSize: 15 }}>{parse(t('how_does_it_work_card4_description'))} <a
              href={`${process.env.REACT_APP_FRONTEND_URL}/openapi/20`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleExternalLink(e.currentTarget.href);
              }}
              style={{ color: 'rgb(7, 253, 235)', textDecoration: 'underline' }}
            >{t('how_does_it_work_card4_description_link')}</a> {t('how_does_it_work_card4_description_2')}</Text>
          </div>
        </Card>,
      elementRef: useRef()
    },
    {
      id: 4,
      element: (paramRef, paramStyle, height) =>
        <Card ref={paramRef}
          className='card-carrousel'
          style={{ ...paramStyle, height }}
        >
          <img src={key} alt='key' style={{ width: 170, height: 150 }} />
          <div style={{ textAlign: 'left', marginBottom: '10px' }}>
            <Title level={4} style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>{t('how_does_it_work_card5_title')}</Title>
            <Text style={{ color: '#fff', fontSize: 15 }}>{t('how_does_it_work_card5_description')}</Text>
          </div>
        </Card>,
      elementRef: useRef()
    },
    {
      id: 5,
      element: (paramRef, paramStyle, height) =>
        <Card ref={paramRef}
          className='card-carrousel'
          style={{ ...paramStyle, height }}
        >
          <img src={apis} alt='inscripcion' style={{ width: 150, height: 150 }} />
          <div style={{ textAlign: 'left' }}>
            <Title level={4} style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>{t('how_does_it_work_card6_title')}</Title>
            <div style={{ textAlign: 'left', marginBottom: '10px' }}>
              <Text style={{ color: '#fff', fontSize: 15 }}>{t('how_does_it_work_card6_description')}</Text>
            </div>
          </div>
        </Card>,
      elementRef: useRef()
    },
    {
      id: 6,
      element: (paramRef, paramStyle, height) =>
        <Card ref={paramRef}
          className='card-carrousel'
          style={{ ...paramStyle, height }}
        >
          <img src={inscripcion} alt='construye' style={{ width: 170, height: 150 }} />
          <div style={{ textAlign: 'left' }}>
            <Title level={4} style={{ color: '#fff', fontSize: 30, marginTop: 5 }}>{t('how_does_it_work_card7_title')}</Title>
            <div style={{ textAlign: 'left', marginBottom: '10px' }}>
              <Text style={{ color: '#fff', fontSize: 15 }}>{t('how_does_it_work_card7_description')}</Text>
              <br />
            </div>
          </div>
        </Card>,
      elementRef: useRef()
    },
    //Documentacion
    {
      id: 7,
      element: (paramRef, paramStyle, height) =>
        <>
          <Card ref={paramRef}
            className='card-carrousel'
            style={{ ...paramStyle, height }}
          >
            <div style={{ textAlign: 'left' }}>
              <Title level={4} style={{ color: '#fff', fontSize: 30, marginTop: 0 }}>
                {t('how_does_it_work_card8_title')}
              </Title>
              <div style={{ textAlign: 'left', marginBottom: '10px' }}>
                <Text style={{ color: '#fff', fontSize: 15 }}>
                  {t('how_does_it_work_card8_description')}</Text>
                <br />
                <br />
                <Button style={{ backgroundColor: '#fff', color: '#000' }}
                  shape='round'
                  onClick={() => onDownloadGuiaInicio()}>
                  <FileSearchOutlined /> {t('how_does_it_work_card8_btn1')}
                </Button>
              </div>
              {/* inicio botones de api */}
              {productosApi.map((p, indexPack) => {
                // TODO - Evitar renderizados
                // console.log("producto ", p)
                return (
                  <React.Fragment key={`pack-${indexPack}`}>
                    <div>
                      <br />
                      <Text style={{ color: 'rgb(255, 192, 0)', fontWeight: 'bold' }}>
                        {getTextoIdioma(p, "NombreProducto")}
                      </Text>
                      <br />
                    </div>
                    {p.ProductosAsociados.sort(customSort).map((x, indexApi) => {
                      return (
                        <React.Fragment key={`pack-${indexApi}`}>
                          <div>
                            {x.IdProducto !== 20 && x.IdProducto !== 19 && (
                              <Text style={{ color: '#fff' }}>
                                {getTextoIdioma(x, "NombreProducto")}
                              </Text>
                            )}
                          </div>
                          <div style={{ textAlign: 'left', marginBottom: '10px' }}>
                            <br />
                            <Button
                              style={{ backgroundColor: '#fff', color: '#000' }}
                              shape='round'
                              onClick={() => onDownloadDocumento(x.IdProducto, TipoDocumentoId.DOCUMENTACION)}
                            >
                              <FileSearchOutlined />
                              {t('how_does_it_work_card8_btn2')}
                            </Button>
                            <Button
                              style={{ backgroundColor: '#fff', color: '#000', marginLeft: 20 }}
                              shape='round'
                              href={x.URLProducto}
                              target="_blank"
                            >
                              {t('how_does_it_work_card8_btn3')}
                            </Button>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                )
              })}

            </div>
            <div style={{ color: '#fff', textAlign: 'left', fontSize: 15, marginTop: 30 }}>
              {t('how_does_it_work_card8_text_help1')}
              <Link style={{ color: 'rgb(7, 253, 235)', textDecoration: 'underline' }} to={'/contacto'} onClick={onRedirect}>{t('how_does_it_work_card8_text_help2')}</Link>
              {t('how_does_it_work_card8_text_help3')}
            </div>
          </Card> </>,
      elementRef: useRef()
    },
  ]


  return (
    <Row ref={ref}>
      {/* <ResizeObserver
        onResize={() => {
          if (!isLoadingCarrusel)
            recalcularAlturas()
        }}
      > */}
      <Col xs={20} sm={20} md={20}>
        {isLoadingCarrusel ? (
          <div style={{ position: 'absolute', top: '30%', left: '55%' }}>
            <Spin size={"large"} />
          </div>
        ) : null}

        <div className='customCarousel como-funciona carousel-container'
        >
          <Carousel
            ref={carouselRef}
            id="carrousel-como-funciona"
            dotPosition={'right'}
            style={{ visibility: isLoadingCarrusel ? 'hidden' : 'inherit' }}
          >
            {cardsCarrousel.map((card, index) => {
              let cardStyle = {}
              const height = index <= 6 ? cardHeight : 'auto';
              //establecemos un margen en todas las tarjetas cuyo with no sea igual al máximo
              if (card.elementRef?.current?.offsetHeight < cardMaxHeight) {
                const marginBotomAux = Math.ceil(
                  (cardMaxHeight - card.elementRef.current.offsetHeight)) - 1

                const marginBotomAuxPx = `${marginBotomAux}px`

                cardStyle = { marginBottom: marginBotomAuxPx }
              }

              return (<React.Fragment key={index} children={card.element(card.elementRef, cardStyle, height)} />)
            })}
          </Carousel>

        </div>
      </Col>

      <Col xs={4} sm={4} md={4} style={{ visibility: isLoadingCarrusel ? 'hidden' : 'inherit' }}>
        <div className={`custom-dots-container ${currentCard === 7 ? 'dot-documentacion' : ''}`}>
          {Array(cardsCarrousel.length).fill().map((_, index) => (
            <span
              key={index}
              className={`custom-dot ${currentCard === index ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </Col>
    </Row >

  );
}

export default CardsCarousel;
