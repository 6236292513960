import { Card, Flex, Spin, Typography, Image } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SwaggerUI from 'swagger-ui-react';
import "swagger-ui-react/swagger-ui.css"
import logo from '../../assets/logos/logo-bolsa-b.png'
import './openApi.scss'
import { getOpenApiSpec } from '../../services/producto';

const { Title } = Typography

const OpenApi = () => {

  const { idProducto } = useParams()
  const [isLoading, setIsLoading] = useState(true)

  const [apiOpenApiSpec, setApiOpenApiSpec] = useState()

  useEffect(() => {
    if (idProducto && idProducto > 0) {
      const fetchApiSpec = async () => {
        setIsLoading(true)
        try {

          const apiSpec = await getOpenApiSpec(idProducto)
          console.log(apiSpec)
          setApiOpenApiSpec(apiSpec.value)

        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
        }
      }
      fetchApiSpec()
    }
  }, [idProducto])

  return (
    <div >
      {isLoading && (
        <>
          <Flex justify="center" >
            <Card style={{ marginTop: "5%" }}>
              <Spin className='spin-swagger' tip="Cargando..." size="large">
                <div />
              </Spin>
            </Card>
          </Flex>

        </>
      )}
      {
        !isLoading && (
          <>
            {apiOpenApiSpec ? (
              <>
                <div className='header-swagger'>
                  <Image
                    preview={false}
                    src={logo}
                  />
                </div>
                <SwaggerUI
                  spec={apiOpenApiSpec}
                  displayRequestDuration={true} />
              </>
            ) : (
              <>
                <Flex align="center" justify="center">
                  <Card>
                    <Title>No se encontró documentación para la api especificada</Title>
                  </Card>
                </Flex>
              </>
            )}
          </>
        )
      }

    </div >
  )
}

export default OpenApi