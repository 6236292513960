import { PublicAxios } from "../services/Helpers/publicInterceptorAxios"

const urlApi = process.env.REACT_APP_BACKEND_URL

export const productosApi = async (idsTiposProductos) => {
    const queryParams = `idsTipoProductos=${idsTiposProductos.toString()}`;
    return PublicAxios.get(`${urlApi}/api/api-externa/producto?${queryParams}`)
        .then((res) => {
            return res.data;
        })
};

export const detalleProductosApi = async (idProducto) => {
    return PublicAxios.get(`${urlApi}/api/api-externa/detalle-producto/${idProducto}`).then((res) => { return res.data })
}

export const getGuiaInicio = async () => {
    try {

        return PublicAxios
        .get(
                `${urlApi}/api/api-externa/GuiaInicio`,
                { responseType: 'arraybuffer' }
            )
            .then((res) => {
                const contentDisposition = res.headers["content-disposition"]
                let startFileNameIndex = contentDisposition.indexOf('"') + 1
                let endFileNameIndex = contentDisposition.lastIndexOf('"');
                let filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);

                return {
                    buffer: res.data,
                    contentType: res.headers["content-type"],
                    fileName: filename
                }
            })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

/**
 * Obtiene un documento de un producto según su tipo
 * @param {number} idProduto - identificador del producto
 * @param {number} idTipoDocumento - identificador del tipo de documento del producto. 1-> Contrato de producto; 2->Folleto producto; 3-> Documentacion
 * @returns {Promise<any>} Archivo en formato "arrayBuffer"
 */
export const getDocumento = async (idProducto, idTipoDocumento) => {
    try {

        return PublicAxios
        .get(
                `${urlApi}/api/api-externa/producto/documento/${idProducto}/${idTipoDocumento}`,
                { responseType: 'arraybuffer' }
            )
            .then((res) => {
                const contentDisposition = res.headers["content-disposition"]
                let startFileNameIndex = contentDisposition.indexOf('"') + 1
                let endFileNameIndex = contentDisposition.lastIndexOf('"');
                let filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);

                return {
                    buffer: res.data,
                    contentType: res.headers["content-type"],
                    fileName: filename
                }
            })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const getOpenApiSpec = async (idProducto) => {
    return PublicAxios.get(`${urlApi}/api/api-externa/producto/${idProducto}/ApiStartup/OpenApi`)
    .then((res) => { return res.data })
}

