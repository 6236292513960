import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import NuestrasApis from '../components/apis/NuestrasApis';
import Contacto from '../components/contacto/Contacto';
import Catalogo from '../components/catalogo/Catalogo';
import Detalle from '../components/catalogo/Detalle';
import OpenApi from '../components/openapi/openapi';


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/home" element={<LandingPage />} />
      <Route path="/nuestras-apis" element={<Catalogo />} />
      <Route path="/detalle-producto/:id" element={<Detalle />} />
      <Route path="/openapi/:idProducto" element={<OpenApi />} />
      <Route path="/como-funciona" element={<NuestrasApis />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
}

export default AppRoutes