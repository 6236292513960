import React from 'react'
import Headers from './Header'
import ContentLanding from './Content'
import FooterLanding from './Footer'
import { Layout } from 'antd'
import { useTranslation } from 'react-i18next'

const LayoutLanding = () => {
  const { t, i18n } = useTranslation();
  return (
    <Layout className='imagen-mobile'>
        <Headers t={t} i18n={i18n}/>
        <ContentLanding t={t} i18n={i18n}/>
        <FooterLanding t={t} i18n={i18n}/>
    </Layout>
  )
}

export default LayoutLanding