export const separadorDeMiles = (numero) => {
    // Convierte el número a string
    let numeroString = numero.toString();
    
    // Separa la parte entera de la parte decimal (si existe)
    let partes = numeroString.split('.');
    let parteEntera = partes[0];
    let parteDecimal = partes.length > 1 ? '.' + partes[1] : '';

    // Agrega separadores de miles a la parte entera
    let separado = '';
    for (let i = parteEntera.length - 1, j = 0; i >= 0; i--, j++) {
        separado = parteEntera[i] + separado;
        if (j % 3 === 2 && i !== 0) {
            separado = '.' + separado;
        }
    }

    // Retorna el número formateado con los separadores de miles
    return separado + parteDecimal;
}

export const  capitalize = (text) => {
    const firstLetter = text.charAt(0);
    const rest = text.slice(1);
    return firstLetter.toUpperCase() + rest;
}