import logoApi from '../../assets/logos/2 LOGO API horizontal sin isotipo.png'
import logoDashboards from '../../assets/logos/2 LOGO API horizontal sin isotipo.png'
import logoInnovationLab from '../../assets/logos/2 LOGO API horizontal sin isotipo.png'
import { TipoProductoId } from '../../enum/enums'

const LogoProducto = ({ idTipoProducto }) => {
    return (
        <>
            {idTipoProducto === TipoProductoId.PACK_APIS ?
                <img
                    alt="api"
                    src={logoApi}
                    style={{ marginLeft:'24%', width: 130 }}
                />
                : idTipoProducto === TipoProductoId.PACK_REPORTES || idTipoProducto === TipoProductoId.REPORTE ?
                    <img
                        alt="Dashboard"
                        style={{ marginTop: 60,  width: 150 }}
                        src={logoDashboards}
                    /> : idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                        <img
                            alt="innovationLab"
                            style={{ marginTop: 60, width: 150 }}
                            src={logoInnovationLab}
                        /> : null}
        </>
    )
}

export default LogoProducto