import React from 'react'
import LayoutLanding from '../components/layout/Layout'

const LandingPage = () => {
  return (
    <div>
        <LayoutLanding/>
    </div>
  )
}

export default LandingPage