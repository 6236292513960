import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography, Spin, Flex } from 'antd';
import { productosApi } from '../../services/producto';
import flecha from '../../assets/images/flecha.png'
import './NuestrasApis.scss';
import CardsCarousel from './CardsCarousel';
import parse from 'html-react-parser';
import SliderApisMobile from './SliderApisMobile';

const { Content } = Layout;
const { Title, Text } = Typography;

const ContentApis = ({ t, i18n }) => {

  const [productosApiList, setProductosaApiList] = useState([])
  const [isLoadingApiInfo, setIsLoadingApiInfo] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingApiInfo(true)
      try {
        const apiProductos = await productosApi([4])
        if (apiProductos) {
          setProductosaApiList(apiProductos)
          console.log('apiProductos: ', apiProductos)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingApiInfo(false)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      <Content className='content-nuestras' style={{ display: 'grid' }}>
        {/* <div className='content-apis' style={{ display: 'grid', height: '100vh' }}> */}
        <div className='content-apis'>
          {/* <div className='content-apis'> */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={6} >
              <div className='contenido-como-funciona'>
                <Title level={1} style={{ textAlign: 'left', fontSize: 30 }}><span>{parse(t('how_does_it_work_title_part1'))}</span> </Title>
                <Text style={{ fontWeight: 400, fontSize: 15, textAlign: 'left' }}><span style={{ color: '#fff' }}>{parse(t('how_does_it_work_description_part1'))}  </span><span style={{ color: '#fff', fontWeight: 'bold' }}> {t('how_does_it_work_description_part4')}</span><span style={{ color: '#fff' }}>.</span>
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>{t('how_does_it_work_description_part5')}</span>
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>{t('how_does_it_work_description_part6')}</span></Text>
                <br />
                <br />
                <img src={flecha} className='flecha' style={{ marginLeft: 'auto', display: 'block' }} alt='flecha' />
              </div>
            </Col>
            <Col md={2}></Col>


            <Col xs={0} sm={0} md={16} >
              {isLoadingApiInfo ? (
                <div style={{ minHeight: '100vh' }}>
                  <Flex style={{ height: "100%", marginTop: 200 }} justify="center" align="center" vertical>
                    <Spin size={"large"} />
                  </Flex>
                </div>
              ) : (
                <div>
                <CardsCarousel t={t} i18n={i18n} productosApi={productosApiList} />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Content>
      <Row>
        <Col xs={24} sm={24} md={0}>
          <SliderApisMobile t={t} i18n={i18n} productosApi={productosApiList}/>
        </Col>
      </Row>
    </>
  );
}

export default ContentApis;
