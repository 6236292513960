import { Button, Card, Col, Flex, Layout, Row, Space, Typography } from 'antd';
import './Layout.scss';
import LogoBolsa from '../../assets/images/logo-bolsa-w.svg';
import { BulbOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom';
import acuerdo from '../../assets/images/apreton-de-manos.png'

const { Content } = Layout;
const { Title, Text } = Typography;
const ContentLanding = ({ t, i18n }) => {

    const navigate = useNavigate();

    const handleExternalLink = (url) => {
        window.open(url, "_blank");
    }

    const onRedirect = (url) => {
        window.scrollTo(0, 0)

        if (url) {
            navigate(url)
        }
    }

    return (
        <Content className='content-layout'>
            <div className='contenido'>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} className='titulo' >
                        <img src={LogoBolsa} alt='logo-bolsa' width={200} />
                        <p className='estilo-texto' style={{ marginTop: 5 }}>
                            <span style={{ color: '#FFC000' }}>{t('text_home_part1')}   </span><span style={{ color: '#7E7E7E' }}>{t('text_home_part2')} </span><span style={{ color: '#FFC000' }}>{t('text_home_part3')} </span><span style={{ color: '#7E7E7E' }}> {t('text_home_part4')} <span style={{ color: '#FFC000' }}>{t('text_home_part5')} </span><span style={{ color: '#7E7E7E' }}>{t('text_home_part6')} </span></span>
                        </p>
                    </Col>
                </Row>
                <Row gutter={0}>
                    <Col xs={24} sm={24} md={6}>
                        {/*<Space direction="horizontal" size="middle"  align="center"
                            style={{ display: 'flex' }}>*/}
                        <Flex justify='space-around'>
                            <Button shape='round' className='btn-nuestras' onClick={() => onRedirect('/nuestras-apis')}>{t('our_apis')}</Button>
                            <Button className='btn-como-funciona' shape='round' style={{ backgroundColor: '#000', color: '#fff' }} onClick={() => onRedirect('/como-funciona')}>{t('how_does_it_work')}</Button>
                        </Flex>
                        {/*</Space>*/}
                    </Col>
                </Row>
                <Row className='cards' gutter={[16, 16]} >
                    <Col xs={24} sm={24} md={8} className="gutter-row" >
                        <Card style={{ marginTop: 36, color: '#fff', height: 190 }} className='card-layout card-layout-1' bordered={false}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                <CheckCircleOutlined style={{ fontSize: 24, marginRight: 8, color: '#06EFFE' }} />
                                <div>
                                    <Title level={5} style={{ color: '#fff', marginTop: 7 }}>{t('text_home_card1_title')}</Title>
                                </div>
                            </div>
                            <div>
                                <Text style={{ color: '#fff' }}>
                                    {t('text_home_card1_description1')}<Link style={{ color: 'rgb(7, 253, 235)', textDecoration: 'underline' }} to={'/como-funciona'} onClick={() => onRedirect()}>{t('text_home_card1_description2')}</Link>{t('text_home_card1_description3')}
                                </Text>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8} className="gutter-row" >
                        <Card style={{ marginTop: 36, color: '#fff', height: 190 }} className='card-layout card-layout-2' bordered={false}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                <img src={acuerdo} style={{ fontSize: 24, marginRight: 8, color: '#06EFFE', width: 30, height: 20 }} alt='acuerdo' />
                                <div>
                                    <Title level={5} style={{ color: '#fff', marginTop: 7 }}>{t('text_home_card2_title')}</Title>
                                </div>
                            </div>
                            <div>
                                <Text style={{ color: '#fff' }}>
                                    {t('text_home_card2_description')}
                                </Text>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row className='card-row' gutter={[16, 16]} >
                    <Col xs={24} sm={24} md={8} className="gutter-row" >
                        <Card style={{ color: '#fff', height: 190 }} className='card-layout card-layout-3' bordered={false}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                <BulbOutlined style={{ fontSize: 24, marginRight: 8, color: '#06EFFE' }} />
                                <div>
                                    <Title level={5} style={{ color: '#fff', marginTop: 7 }}>{t('text_home_card3_title')}</Title>
                                </div>
                            </div>
                            <div>
                                <Text style={{ color: '#fff' }}>
                                    {t('text_home_card3_description')}
                                </Text>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginTop: 40 }} gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}>

                    <Col span={24}>
                        <Title level={3} style={{ color: '#fff' }}>{t('text_section_title')}</Title>
                        <Text style={{ color: '#fff', fontSize: 15 }}><span>{t('text_section_description')}
                            <a style={{ color: 'rgb(7, 253, 235)', textDecoration: 'underline' }}
                                href={process.env.REACT_APP_URL_ONBOARDING_PUBLICO}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleExternalLink(e.currentTarget.href);
                                }}
                            >
                                {t('text_section_description_register')}
                            </a></span></Text>
                    </Col>
                </Row>
            </div>
        </Content>
    )
}

export default ContentLanding