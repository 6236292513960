export const esp = {
    home: 'Inicio',
    our_apis: 'Nuestras APIs',
    how_does_it_work: '¿Cómo funciona?',
    contact: 'Contacto',
    terms_conditions: 'Términos y Condiciones',
    text_home_part1: 'Conéctate',
    text_home_part2: ' a la principal fuente de',
    text_home_part3: ' datos bursátiles en Chile',
    text_home_part4: ' y haz realidad',
    text_home_part5: ' tus proyectos',
    text_home_part6: '',
    text_home_card1_title: 'Simple',
    text_home_card1_description1: "Utilizar nuestras APIs es fácil y rápido, solo debes seguir los pasos que se encuentran en la sección ",
    text_home_card1_description2: "Cómo funciona ",
    text_home_card1_description3: "y estás listo para consultar.",
    text_home_card2_title: 'Confiable',
    text_home_card2_description: 'Tendrás acceso a nuestra data de forma directa, sin intermediarios.',
    text_home_card3_title: 'Comprometidos con tus objetivos',
    text_home_card3_description: 'Creemos firmemente en la democratización de la información financiera. Con nuestras APIs despreocúpate de la data y enfócate en la construcción de tus aplicaciones.',
    text_section_title: '¿Te interesa?',
    text_section_description: 'Descubre el poder de nuestras APIs y ',
    text_section_description_register: 'regístrate.',
    our_apis_title: 'Catálogo de APIs',
    our_apis_description1: 'En nuestro catálogo podrás ver todas las APIs que tenemos disponibles para ti.',
    our_apis_description2: 'Haz click en cualquiera para conocer en mayor detalle lo que ofrecen, sus planes y documentación.',
    our_apis_card1_title: 'Bolsa Free',
    our_apis_card1_description: 'API que entrega información del mercado de Renta Variable de manera limitada.',
    our_apis_card1_description_free: 'Acceso gratuito.',
    our_apis_card2_title: 'Mercado de Valores',
    our_apis_card2_description: 'API que entrega información de los mercados de Renta Variable, Renta Fija e Intermediación Financiera.',
    our_apis_card3_title: 'Compensación y Garantías',
    our_apis_card3_description: 'API que entrega información de la Compensación y Liquidación de operaciones.',
    our_apis_cards_btn_details: 'Ver más detalle',
    how_does_it_work_title_part1: '<span style="color:rgb(126, 126, 126);">¿En </span><span style="color:#FFC000;">qué consiste</span> <span style="color:rgb(126, 126, 126);">este servicio? </span>',
    how_does_it_work_description_part1: 'API Brain Data te permite <span style="color:#FFC000;"> crear tus propias aplicaciones </span> a partir de la fuente de datos bursátiles más confiable de ',
    how_does_it_work_description_part2: 'crear tus propias aplicaciones',
    how_does_it_work_description_part3: 'a partir de la fuente de datos bursátiles más confiable de ',
    how_does_it_work_description_part4: 'Chile',
    how_does_it_work_description_part5: 'Si eres un desarrollador, startup, inversionista o empresa, regístrate y comienza a construir con nuestras APIs.',
    how_does_it_work_description_part6: 'Para utilizar este servicio solo debes seguir los siguientes pasos:',
    how_does_it_work_card1_title: 'Descubre',
    how_does_it_work_card1_description: 'Conoce las APIs que tenemos disponibles ',
    how_does_it_work_card1_description_link: 'aquí ',
    how_does_it_work_card1_description_2: 'y revisa su documentación para ver cómo funcionan y qué datos entrega cada una.',
    how_does_it_work_card2_title: 'Registro y contratación',
    how_does_it_work_card2_description: 'Regístrate en nuestro Portal Marketplace y contrata las APIs que mejor se adapten a tus necesidades, allí podrás gestionar tus suscripciones y hacer un upgrade de plan cuando quieras.',
    how_does_it_work_card3_title: 'Request',
    how_does_it_work_card3_description: 'De acuerdo al plan que elijas tendrás una cantidad diaria o mensual de requests para que puedas consultar información. En nuestro Portal Marketplace podrás revisar en todo momento cuánto has utilizado y sabrás cuándo se ha agotado tu stock.',
    how_does_it_work_card4_title: 'Prueba gratis',
    how_does_it_work_card4_description: 'Recuerda que tenemos disponible una versión gratuita donde podrás probar nuestra  ',
    how_does_it_work_card4_description_link: 'API de Renta Variable',
    how_does_it_work_card4_description_2: ' con instrumentos y requests limitados.',
    how_does_it_work_card5_title: 'API Key',
    how_does_it_work_card5_description: 'Una vez contrates un plan, en tu perfil Portal Marketplace obtendrás tu API Key o Token de seguridad, el cual deberás utilizar para realizar consultas a nuestras APIs.',
    how_does_it_work_card6_title: 'Experimenta',
    how_does_it_work_card6_description: 'Para experimentar con nuestras APIs debes dirigirte a la API contratada e ingresar tu Token.',
    how_does_it_work_card6_subtitle: 'API Mercado de Valores',
    how_does_it_work_card6_subtitle2: 'API Compensación y Garantías',
    how_does_it_work_card6_text_part1: 'Renta Variable',
    how_does_it_work_card6_text_part2: 'Renta Fija',
    how_does_it_work_card6_text_part3: 'Intermediación Financiera',
    how_does_it_work_card6_btn: 'API',
    how_does_it_work_card7_title: 'Construye',
    how_does_it_work_card7_description: 'Listo! Ahora ya estás conectado con nuestro principal centro de datos y comenzar a construir para hacer realidad tus proyectos.',
    how_does_it_work_card8_title: 'Documentación',
    how_does_it_work_card8_description: 'Te sugerimos revisar nuestra documentación de inicio e instructivo de cada API para que puedas aprovechar al máximo tu suscripción.',
    how_does_it_work_card8_subtitle1: 'API Mercado de Valores',
    how_does_it_work_card8_subtitle2: 'API Bolsa Free',
    how_does_it_work_card8_subtitle3: 'API Compensación y Garantías',
    how_does_it_work_card8_text_part1: 'Renta Variable',
    how_does_it_work_card8_text_part2: 'Renta Fija',
    how_does_it_work_card8_text_part3: 'Intermediación Financiera',
    how_does_it_work_card8_btn1: 'Guía de inicio',
    how_does_it_work_card8_btn2: 'Manual',
    how_does_it_work_card8_btn3: '</> API',
    how_does_it_work_card8_text_help1: 'Si tienes alguna duda, dirígete a nuestra sección de ',
    how_does_it_work_card8_text_help2: 'Contacto ',
    how_does_it_work_card8_text_help3: 'para enviarnos un mensaje.',
    contact_title_part1: 'Ponte en contacto',
    contact_title_part2: 'con nosotros',
    contact_description_1: '¿Tienes dudas o alguna necesidad particular que podamos atender con nuestras APIs?',
    contact_description_2: 'Escríbenos y te contactaremos a la brevedad para resolver todas tus inquietudes.',
    contact_form_title: '¡Hablemos!',
    contact_form_select1: 'Consulta',
    contact_form_select2: 'Sugerencia',
    contact_form_select3: 'Necesito ayuda',
    contact_form_select4: 'Otro',
    contact_form_btn: 'Enviar mensaje',
    contact_placeholder_select: 'Selecciona el motivo de tu consulta',
    contact_placeholder_input_email: 'Correo',
    contact_placeholder_input_message: 'Escribe tu mensaje',
    contact_Error_message_1: 'Selecciona tu consulta',
    contact_Error_message_2: 'Ingresa tu correo',
    contact_Error_message_3: '',
    contact_frequently_asked_questions_title: 'Preguntas frecuentes',
    contact_frequently_asked_questions_subtitle: 'A continuación, te entregamos una selección de las preguntas que recibimos con mayor frecuencia y sus respuestas.',
    contact_frequently_asked_questions_card1_title: 'Documentación', 
    contact_frequently_asked_questions_card2_title: 'Centro de ayuda',
    contact_frequently_asked_questions_card3_title: 'Soporte',
    contact_frequently_asked_questions_card4_title: 'Ventas',
    details_products_plan: 'Este plan incluye:',
    details_description_subtitle: 'Descripción del producto',
    details_types_plans: 'Tipos de planes disponibles para esta API:',
    details_button_return: 'Volver al catálogo',
    details_button_contract: 'Ver contrato del producto',
    details_button_doc: 'Manual',
    details_cards_request: 'Request / mes',
    details_cards_requestFree: 'Request / día',
    details_cards_market: '',
    details_cards_instruments: 'instrumentos aleatorios',
    details_cards_historicity_part1: 'Historicidad de',
    details_cards_historicity_part2: 'año',
    details_cards_button1: 'Prueba gratis',
    details_cards_button2: 'Contratar plan',
    details_cards_month: 'mes',
    details_cards_subtitle0: 'Comienza probando',
    details_cards_subtitle1: 'Para comenzar',
    details_cards_subtitle2: 'Para desarrolladores',
    details_cards_subtitle3: 'Por si necesitas más',
    details_name_plan_free: 'Básico',
    frequently_asked_questions_title1: '¿Qué es API Brain Data?',
    frequently_asked_questions_text1: 'Brain Data es el ecosistema de productos y servicios basados en una arquitectura Cloud de Big Data & Analytics implementada en la Bolsa de Santiago.',
    frequently_asked_questions_text2: 'API es parte de este ecosistema, siendo este producto un mecanismo para que aplicaciones se puedan conectar a los principales datos bursátiles que ofrece la Bolsa. Esta interacción se realiza mediante consultas y respuestas, donde la aplicación envía una solicitud de información a la API, y luego esta le envía una respuesta con la data solicitada.',
    frequently_asked_questions_title2: '¿Para quién está pensado este producto?',
    frequently_asked_questions_text4: 'Este producto está disponible para inversionistas amateurs, desarrolladores, startups o empresas que deseen acceder a los datos de la Bolsa de Santiago.',
    frequently_asked_questions_title3: '¿Qué APIs ofrecen?',
    frequently_asked_questions_text5: 'Actualmente tenemos disponible 3 APIs:',
    frequently_asked_questions_text6: 'API Free Trial: esta entrega información limitada de manera gratuita del mercado de Renta Variable chileno.',
    frequently_asked_questions_text7: 'API Mercado de Valores: esta entrega información con 15 minutos de delay sobre los mercados de Renta Variable, Renta Fija e Intermediación Financiera chilena.',
    frequently_asked_questions_text8: 'API Compensación y Garantías: esta entrega información con 15 minutos de delay sobre la Compensación y Liquidación de operaciones de la Bolsa.',
    frequently_asked_questions_title4: '¿Qué puedo hacer con las APIs?',
    frequently_asked_questions_text9: 'Al conectarte a nuestras APIs podrás crear productos y aplicaciones financieras con información confiable sin intermediarios, asimismo, puedes consultar la información disponible para realizar potentes análisis financieros. Dentro de los manuales de cada API podrás encontrar casos de uso para inspirarte y comenzar a construir.',
    frequently_asked_questions_text91: 'Es importante mencionar que la licencia otorgada al contratar alguna de estas APIs es solo para uso interno del cliente, si deseas distribuir la información entregada por API Brain Data a terceros contáctate con nosotros para revisar las tarifas y condiciones asociadas.',
    frequently_asked_questions_title5: '¿Cómo me conecto a las APIs?',
    frequently_asked_questions_text10: 'Primero deberás registrarte en nuestro Marketplace, donde podrás ver la oferta de productos que tenemos disponibles para ti. Luego en este portal tendrás que contratar las APIs que necesites o utilizar la API gratuita que tenemos para que pruebes cómo funciona.',
    frequently_asked_questions_text11: 'Una vez contratada la API, obtendrás tu token de seguridad o API Key con el cual podrás conectarte a la API.',
    frequently_asked_questions_text12: 'Revisa nuestra guía de inicio y los manuales de cada API para conocer en mayor detalle cómo utilizar y aprovechar nuestro producto.',
    frequently_asked_questions_title6: '¿De dónde proviene la data de las APIs?',
    frequently_asked_questions_text13: 'La información entregada por las APIs proviene directamente del repositorio central de datos de la Bolsa de Santiago, el cual se encuentra en una infraestructura Cloud.',
    frequently_asked_questions_title7: '¿Qué significan los Requests?',
    frequently_asked_questions_text14: 'Los Requests son la cantidad límite de consultas que puedes realizar a las APIs ya sea en un día o en un mes. Nuestros planes se diferencian principalmente por la cantidad de requests que tienes disponibles. Los errores que puedan surgir no son contabilizados dentro de tu bolsa de requests.',
    frequently_asked_questions_title8: '¿Tengo que pagar para usar las APIs?',
    frequently_asked_questions_text15: 'Depende de la API que consultes, la API Free Trial es la única gratuita donde obtienes información limitada. Las API de Mercado de Valores y de Compensación y Garantías son de pago, en la sección Nuestras APIs podrás ver los tipos de planes que tenemos para cada una junto a las tarifas asociadas.',
    frequently_asked_questions_title9: '¿Qué métodos de pago aceptan?',
    frequently_asked_questions_text16: 'Puedes suscribirte con tarjetas de débito o crédito, y también tenemos disponibles como medio de pago transferencias electrónicas. Con cualquiera de estas opciones deberás pagar manualmente cada mes para que no se dé de baja tu suscripción.',
    frequently_asked_questions_title10: '¿Qué pasa si alcanzo la cuota máxima de requests que contraté?',
    frequently_asked_questions_text17: 'Los requests son limitados para cada plan, por lo que si llegas al límite ya no podrás seguir realizando consultas a menos que te cambies de suscripción a una mayor.',
    frequently_asked_questions_title11: '¿Cómo me puedo cambiar de plan?',
    frequently_asked_questions_text18: 'Puedes autogestionar tus productos contratados y suscripciones en Marketplace, donde podrás realizar un upgrade a tu plan si es que lo necesitas. Para llevar a cabo esto debes ingresar tus credenciales en el portal web.',
    frequently_asked_questions_title12: '¿Puedo cancelar mi suscripción en cualquier momento?',
    frequently_asked_questions_text19: 'Sí, puedes cancelar cuando quieras tu suscripción en Marketplace, podrás seguir utilizando la API hasta que se cumpla el último periodo de pago. Para llevar a cabo esto debes ingresar tus credenciales en el portal web.',
    instruments: 'Puedes seleccionar cualquier instrumento',
    delay: 'Delay de 15 minutos',
    modal_message_1: 'Mensaje enviado correctamente. ',
    modal_message_2: 'Alguien de nuestro equipo te contactará a la brevedad y atenderá tu solicitud.',
    modal_message_3: 'Aceptar',
    title1: 'A. TÉRMINOS Y CONDICIONES DE ACCESO Y USO DE SITIO WEB API BRAIN DATA',
    text1: 'A continuación, se describen los términos y condiciones legales (los “Términos y Condiciones”) sobre los cuales Holding Bursátil Regional S.A. (la “Empresa” o “HBR”) identificada con RUT 77.726.203 - 3, domiciliada en Chile, representada legalmente por Don Patricio Rojas Sharovsky, ofrece a usted acceso al sitio web: www.api-braindata.bolsadesantiago.com, junto con cualquier subdominio relacionado (el “Sitio web”), como usuario del mismo (“Usuario”).',
    text2: 'Por favor lea la Política de Privacidad y los siguientes Términos y Condiciones antes de utilizar nuestro Sitio web. Al acceder y usar nuestro Sitio web, significa que usted acepta estos Términos y Condiciones y la Política de Privacidad del Sitio web. Nos reservamos el derecho de modificar y/o eliminar estos Términos y Condiciones y/o la Política de Privacidad en cualquier momento. Por favor consulte el título “Última revisión” en la parte inferior para ver la fecha en la cual se actualizaron por última vez los Términos y Condiciones. Cualquier cambio en los Términos y Condiciones entrará en vigencia una vez que se publique',
    text3: 'Si usted no acepta los Términos y Condiciones, no podrá utilizar nuestro Sitio web. Lo mismo ocurrirá en caso de que no acepte cualquiera de sus modificaciones posteriores o bien decida revocar su anterior aceptación de los mismos.',
    text4: 'Todos los términos y condiciones propuestos por Usted que sean adicionales o que entren en conflicto con estos Términos y Condiciones son expresamente rechazados por la HBR y no tendrán fuerza ni efecto.',
    title2: '1. Disclaimer',
    text5: 'Cualquier material, información y/o contenido del Sitio web (“Contenido”) se proporciona “tal cual”, por lo que la HBR, sus empleados, funcionarios, directores, agentes y licenciadores o las compañías de su grupo empresarial (“las Compañías”) no pueden garantizar y no garantizan la disponibilidad del Contenido o Sitio web, ni su precisión, integridad, actualidad, puntualidad, no infracción, título, comerciabilidad o idoneidad para algún propósito en particular que Usted le dé al Contenido o al Sitio web en sí mismo.',
    text6: 'El Contenido es sólo para fines informativos y no pretende constituir una oferta de valores, consejo de inversión o asesoramiento comercial. El Contenido no tiene en cuenta los objetivos de inversión de los Usuarios que lo visitan, su situación financiera o necesidades particulares. Es aconsejable que el Usuario, antes de actuar sobre cualquier información contenida en el Sitio web o Contenido, busque asesoramiento profesional que le permita complementar y contrastar la información que encontrará en el Sitio web.',
    text7: 'El Contenido se revisa en profundidad antes de su publicación, sin embargo, no es posible garantizar que se encuentre absolutamente libre de errores mecanográficos, defectos de redacción y problemas equivalentes, por lo que HBR recomienda a los Usuarios que estén atentos a las posibles actualizaciones o rectificaciones que el Sitio web incorpore y que confirmen los datos que resulten de interés esencial para ellos a través de los cauces de comunicación personal, telefónica y correo electrónico que se proporcionan en este mismo Sitio web y en los medios de comunicación',
    text8: 'En ningún caso HBR, sus empleados, funcionarios, directores, agentes o licenciadores y/o las Compañías serán responsables ante usted o cualquier tercero por cualquier tipo de pérdida financiera, pérdida de ganancias, cualquier daño moral, especial, incidental o de cualquier tipo de daño similar, resultante directa o indirectamente del uso del Contenido o Sitio web.',
    text9: 'En ningún caso, HBR, sus empleados, funcionarios, directores, agentes, licenciadores o las Compañías serán responsables ante usted o cualquier tercero por cualquier decisión o acción tomada por usted o cualquier tercero en función del Contenido o del Sitio web en sí mismo.',
    title3: '2. Restricciones Jurídicas',
    text10: 'Sin limitar lo anterior, usted comprende que las leyes relativas a los contratos financieros varían en todo el mundo, y es su obligación asegurarse de cumplir plenamente con cualquier ley, reglamento o directiva, aplicable a su país de residencia con respecto al uso del Sitio web. La capacidad de acceder al Sitio web no significa necesariamente que el Sitio web y/o el Contenido, sean legales según las leyes, regulaciones o directivas aplicables en su país de residencia.',
    title4: '3. Restricciones de Uso y Acceso',
    text11: 'Usted será responsable de proporcionar y mantener los medios por los cuales accede al Sitio web, que pueden incluir, entre otros, su computadora personal, ipad, tablet o dispositivo móvil, hardware de conectividad y líneas de telecomunicaciones o acceso a internet.',
    text12: 'Usted declara y garantiza que ha implementado y planea operar y mantener la protección adecuada en relación con la seguridad y el control de su computadora y cualquier información y datos incluidos en ella.',
    text13: 'Usted acepta que HBR no será responsable de ninguna manera ante usted en caso de falla, daño o destrucción en el Sitio web, de datos o registros o cualquier parte de los mismos, o por demoras, pérdidas, errores u omisiones que resulten de la falla o mala administración de cualquier equipo de computación, software o línea de telecomunicación que usted emplee para acceder al Sitio web. Usted acepta que de ninguna manera, ya sea directa o indirectamente, expondrá a HBR, o a cualquiera de los proveedores de servicio de HBR, a virus informáticos u otro material o dispositivo igualmente dañino o inapropiado.',
    text14: 'Sin limitar la generalidad de lo anterior, el uso del Sitio web por parte de usted como Usuario está sujeto a las siguientes restricciones:',
    text15: 'No puede usar, vender, alquilar, arrendar, copiar, modificar, distribuir, redistribuir, licenciar, sublicenciar, ejecutar o exhibir públicamente, publicar, editar, crear trabajos derivados o hacer un uso no autorizado del Sitio web y/o cualquier Contenido y marcas de HBR o de las Compañías (“Marcas”), sin el previo consentimiento explícito por escrito de HBR o las Compañías según sea el caso. Asimismo, no podrá modificar, realizar trabajos derivados, desensamblar, realizar una compilación inversa o realizar ingeniería inversa en ninguna parte del Sitio web, sin el previo consentimiento explícito por escrito de HBR;',
    text16: 'Salvo que se indique expresamente en este documento, ninguna parte del Sitio web, el Contenido y/o Marcas contenidas en el mismo pueden copiarse, reproducirse, distribuirse, comercializarse, volver a publicarse, descargarse, mostrarse, publicarse o transmitirse de ninguna forma ni por ningún medio, sin el previo consentimiento explícito por escrito de HBR o las Compañías según sea el caso. Si el Usuario elige descargar Contenido, debe hacerlo de acuerdo con los Términos y Condiciones y HBR le autoriza dicha descarga solo para su uso personal y no comercial y no le transfiere ningún derecho sobre el mismo;',
    text17: 'El Sitio web así como el Contenido no se pueden utilizar para construir una base de datos de ningún tipo, así como tampoco se puede almacenar el Sitio web (en su totalidad o cualquier parte) en bases de datos para que usted o un tercero accedan a él o para distribuir cualquier servicio de bases de datos que contenga todo o parte del Sitio web. No puede utilizar el Sitio web de ninguna manera para mejorar la calidad de los datos vendidos o aportados por usted a terceros;',
    text18: 'No debe usar el Contenido o el Sitio web para crear un servicio similar o competitivo;',
    text19: 'No puede utilizar el Sitio web en relación con material que promueva actividades ilegales o la violación de cualquier ley o regulación local, estatal, nacional o internacional, incluidas, entre otras, las leyes que rigen la propiedad intelectual y otros derechos de propiedad (incluidos, pero no limitado a, patentes, marcas registradas, secretos comerciales, derechos de autor o cualquier información confidencial, de propiedad o secreto comercial de un tercero), protección de la información y privacidad, incluido, entre otros, el contenido que difunde la información personal de otra persona sin su permiso;',
    text20: 'El Sitio web no puede ser utilizado ni accedido por ninguna máquina automatizada, scrapping, robot, araña o cualquier otra función o servicio automatizado;',
    text21: 'No debe hacerse pasar por otra persona o entidad ni tergiversar la afiliación, conexión o asociación con ninguna persona o entidad ni utilizar información de contacto fraudulenta, engañosa o inexacta;',
    text22: 'No puede eliminar, eludir, deshabilitar, dañar o interferir de otro modo con ninguna función del Sitio web, ni intentar obtener acceso no autorizado a ninguna parte de los mismos a través de ningún medio, ni interferir, corromper o interrumpir el funcionamiento o desempeño del Sitio web o la capacidad de cualquier otra persona para utilizarlo (incluido, entre otros, el intento de degradar el rendimiento de los servidores de alguna manera);',
    text23: 'No puede violar los derechos de privacidad, publicidad y otros derechos de otros usuarios o terceros, o recolectar, raspar, agregar datos, extraer datos, raspar la pantalla, indexar o recopilar datos e información sobre otros usuarios o terceros sin su consentimiento, ya sea de forma manual o automática con el uso de cualquier medio, incluidos, entre otros, bots, rastreadores, arañas, tamices y probadores de carga, sin el consentimiento expreso por escrito de HBR, o participar en pruebas, pen-testing, sniffing o monitoreo de los servicios, sus sistemas, software o hardware de cualquier forma;',
    text24: 'No puede utilizar el Sitio web en material que una persona razonable podría considerar: ofensivo, inexacto, incompleto, abusivo, obsceno, censurable, difamatorio, calumnioso, fraudulento o engañoso, indecente, pornográfico, profano, amenazante, acoso o intimidación, angustiante, vulgar, odioso, malicioso, dañino para menores, racial o étnicamente ofensivo, intolerancia, odio o daño físico de cualquier tipo contra cualquier grupo o individuo, o menospreciar la agenda religiosa, política o legal de cualquier persona o entidad, o que sea de otra manera inconsistente con estos Términos y Condiciones, incluida cualquiera de nuestras políticas; Usted acepta informar cualquier violación de los Términos y Condiciones por parte de otras personas de las que tenga conocimiento.',
    title5: '4. Derechos de la Empresa',
    text25: 'HBR se reserva el derecho de modificar, suspender o interrumpir el funcionamiento del Sitio web o de cualquier parte o secciones del mismo en cualquier momento y sin previo aviso. Usted acepta que HBR no será responsable ante usted o ningún tercero y no se admitirán reclamaciones contra la Empresa en relación a ello.',
    title6: '5. Fuerza Mayor',
    text26: 'Usted acepta que HBR no será responsable de ninguna manera ante Usted o cualquier otra persona en caso de que exista fuerza mayor (incluido, entre otros, el acto de cualquier gobierno o autoridad legal), por la falla, daño o destrucción de sus sistemas informáticos, datos o registros o cualquier parte de los mismos.',
    title7: '6. Problemas Técnicos',
    text27: 'Usted comprende que, los problemas técnicos u otras condiciones pueden retrasar o impedir que acceda al Sitio web.',
    text28: 'De acuerdo a la legislación aplicable, HBR no será responsable, y usted acepta no responsabilizar ni buscar responsabilizar a HBR ni a ninguno de sus agentes o proveedores de servicios, por cualquier problema técnico, demoras, fallas del Sitio web, fallas en la línea de comunicación, fallas en el equipo o software, o por demoras, pérdidas, errores u omisiones que resulten de la falla o mala administración de cualquier equipo o software de telecomunicaciones o de computadora, problemas de acceso al Sitio web, problemas de capacidad del sistema, alta demanda de tráfico de Internet, y otros problemas y defectos informáticos similares.',
    text29: 'HBR no garantiza que usted podrá acceder o utilizar el Sitio web en los momentos o lugares que elija, o que HBR tendrá la capacidad adecuada para el Sitio Web en su conjunto o en cualquier ubicación geográfica.',
    title8: '7. Contenido del Usuario',
    text30: '“Contenido del Usuario” significa toda la información y el contenido que Usted ingresa o envía al Sitio web. Usted asume todas las responsabilidades y riesgos asociados con el uso del Contenido del Usuario, incluida su veracidad, precisión, integridad y el uso del mismo por parte de terceros que accedan a él en el Sitio web, así como cualquier consecuencia por su publicidad o divulgación más allá del Sitio web.',
    text31: 'Por la presente, Usted declara y garantiza que: (a) es el propietario del Contenido del Usuario o tiene suficientes derechos y autoridad sobre el mismo; (b) el Contenido del Usuario no viola los Términos y Condiciones ni la Política de Privacidad, ni ninguna ley, regulación o directiva aplicable; (c) el Contenido del Usuario no contiene ningún virus, adware, spyware, worms u otro código dañino o malicioso; y (d) usted autoriza a HBR a usar el Contenido del Usuario de acuerdo a los Términos y Condiciones y a la Política de Privacidad.',
    text32: 'HBR no está obligado a realizar copias de seguridad de ningún Contenido del Usuario y éste podrá ser eliminado en caso de que HBR deje de tener una base de legalidad para procesarlo. Usted es el único responsable de crear las respectivas copias de seguridad.',
    text33: 'Al publicar o cargar Contenido del Usuario en el Sitio web, Usted, con las limitaciones de la legislación aplicable, otorga a HBR una licencia mundial irrevocable, no exclusiva, libre de regalías y totalmente pagada para reproducir, distribuir públicamente, mostrar y ejecutar, preparar trabajos derivados, incorporarlos a otros trabajos y utilizar de cualquier otro modo el Contenido del Usuario, y otorgar sublicencias de lo anterior.',
    text34: 'HBR se reserva el derecho (pero no tiene la obligación, excepto en la medida en que lo exija la ley aplicable) de revisar cualquier Contenido del Usuario, investigar y/o tomar las medidas adecuadas contra Usted a su entera discreción (incluida la eliminación o modificación de su Contenido del Usuario y/o informarlo a las autoridades policiales).',
    title9: '8. Marcas Comerciales y Derechos de Autor',
    text35: 'Todos los derechos, títulos e intereses relacionados con el Sitio web, el Contenido y las Marcas, incluidas, entre otras, las marcas comerciales, las marcas de servicios, los nombres comerciales y el logotipo "NuamExchange" y el de las Compañías son propiedad de HBR, las Compañías u otros otorgantes de licencias y están protegidos por leyes de derechos de autor, propiedad intelectual y marcas registradas y tratados internacionales.',
    text36: 'Usted acepta no eliminar ningún aviso de derechos de autor u otras indicaciones de derechos de propiedad intelectual protegidos del Contenido que imprima o descargue del Sitio web. Usted no obtendrá ningún derecho de propiedad intelectual, ni ningún derecho o licencia para utilizar dicho Contenido, salvo lo establecido expresamente en estos Términos y Condiciones.',
    text37: 'Las imágenes que se muestran en el Sitio web son propiedad de HBR, de las Compañías o de sus licenciantes. Usted acepta no cargar, publicar, reproducir o distribuir ninguna información, software u otro material protegido por derechos de autor o cualquier otro derecho de propiedad intelectual (como derechos de imagen), o que pudiere afectar la privacidad o la protección de datos personales, sin antes obtener el permiso del titular o propietario de dichos derechos y el consentimiento previo por escrito de HBR.',
    text38: 'Nada de lo contenido en el Sitio web puede interpretarse como una concesión, cesión, renuncia, por implicación, impedimento legal o de otro modo, cualquier licencia o derecho para usar cualquier marca comercial, imagen o dato personal, sin el permiso por escrito de HBR, de las Compañías o de cualquier tercero que pueda ser propietario de tales derechos.',
    text39: 'El uso del Contenido, excepto por lo dispuesto en este documento, está estrictamente prohibido.',
    text40: 'Si cree que uno de nuestros Usuarios o que el Sitio web está infringiendo derechos de propiedad intelectual y desea que se elimine el material presuntamente infractor, comuníquese directamente en la sección “Contacto” en el Sitio web.',
    text41: 'Para cualquier consulta relacionada con derechos de propiedad intelectual, comuníquese directamente en la sección “Contacto” en el Sitio web.',
    title10: '9. Incumplimiento',
    text42: 'Usted acepta indemnizar sin límite, defender, declarar indemne y eximir de toda responsabilidad a HBR, a las Compañías y a sus respectivos funcionarios, directores y empleados de cualquier reclamo, demanda, responsabilidad, daño, pérdida, costo y gastos, incluidos los honorarios legales, que puedan surgir como resultado de: (i) cualquier incumplimiento grave de los Términos y Condiciones y la Política de Privacidad por su parte o (ii) incumplimiento grave por su parte de cualquier ley, regulación, directiva o los derechos de un tercero, y que cause un perjuicio directo a la HBR, y/o sus afiliadas corporativas y sus respectivos funcionarios, directores y empleados.',
    text43: 'Sin perjuicio de cualquier otro derecho establecido en estos Términos y Condiciones, si incumple total o parcialmente cualquier disposición contenida en este documento, HBR se reserva el derecho de tomar las medidas que se consideren oportunas.',
    title11: '10. Ley Aplicable y Jurisdicción',
    text44: 'Estos Términos y Condiciones se regirán por las leyes de Chile sin tener en cuenta los conflictos de principios legales de las mismas, independientemente de si usted reside en cualquier otro lugar del mundo y/o si ha accedido al Sitio web desde cualquier parte del mundo.', 
    text45: 'HBR opera el Sitio web en Chile, por lo que HBR no garantiza que el Sitio web y sus derechos de autor, marcas comerciales, patentes y acuerdos de licencia, sean apropiados o estén disponibles para su uso en lugares distintos de Chile. Si accede a este sitio desde ubicaciones fuera de Chile, lo hace por su propia iniciativa y bajo su propio riesgo, y usted es el único responsable del cumplimiento de las leyes locales, en la medida en que las leyes locales sean aplicables.',
    text46: 'Este Sitio web está controlado y operado por HBR desde sus oficinas en Santiago, Chile. HBR no garantiza que los materiales de este Sitio web sean apropiados para su uso en otros lugares. Si alguna parte de estos Términos y Condiciones se considera ilegal, nula o inaplicable, esa parte se considerará separable y no afectará la validez y aplicabilidad de las disposiciones restantes.',
    text47: 'Usted acepta someterse a la jurisdicción personal y exclusiva de los tribunales ubicados dentro de Chile para resolver cualquier disputa que pueda surgir en relación a ello.',
    title12: '11. Otros',
    text48: 'Los títulos de las secciones de los Términos y Condiciones se utilizan únicamente para su conveniencia y la de HBR, careciendo de significado legal o contractual.',
    text49: 'Los Términos y Condiciones, la Política de Privacidad y cualquier otro término y condición de servicio que se incluya en el Sitio web, constituyen el acuerdo completo entre Usted y HBR que rige el uso del Sitio web.',
    title13: 'B. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES',
    title14: '1. General',
    text50: 'Holding Bursátil Regional S.A. ("Empresa" o "HBR") es la propietaria del sitio web www.api-braindata.bolsadesantiago.com ("Sitio web"). HBR se compromete a proteger el tratamiento de sus datos personales y su privacidad. Esta política de privacidad y protección de datos personales (“Política de Privacidad”) está diseñada para informarle sobre el tipo de datos personales recolectados, procesados y almacenados por HBR a través del Sitio web y para qué finalidades se usan. También le informaremos cómo puede ejercer sus derechos de titular de esos datos personales.',
    text51: 'HBR quiere que Usted siempre esté al tanto de los datos personales que entrega al Sitio web, teniendo control sobre la información que comparte tanto en su navegación como cuando completa nuestros formularios de contacto, se suscribe a newsletters o contacta a nuestro servicio al cliente. HBR declara que respeta la privacidad y el derecho a la autodeterminación informativa de toda persona y que es el responsable de los datos tratados en el Sitio web de conformidad con la Política de Privacidad y las leyes de la República de Chile.',
    text52: 'Esta Política de Privacidad no se aplica a los sitios web o aplicaciones accesibles a través de hipervínculos que usted pudiere encontrar en el Sitio web, salvo aquellos que lo dirijan a sitios web o aplicaciones de HBR.',
    title15: '2. Consentimiento para tratamiento de datos personales',
    text53: 'La base legal principal para el tratamiento de los datos personales en este Sitio web es su aceptación explícita para que el Sitio web recolecte, procese, trate y almacene sus datos según esta Política de Privacidad. Al usar el Sitio web Usted acepta y consiente expresamente que se traten sus datos personales de acuerdo con esta política.',
    text54: 'Usted declara ser mayor de 18 años.',
    text55: 'Le recomendamos que revise esta Política de Privacidad detenidamente y que la consulte periódicamente para que la comprenda junto a sus cambios posteriores, si los hubiere. Si no está de acuerdo con los términos de esta Política de Privacidad, no nos suministre dato alguno y deje de utilizar el Sitio web inmediatamente.',
    text56: 'Cualquier cambio a esta Política de Privacidad se publicará en el Sitio web y se le solicitará que acepte los cambios.',
    text57: 'Las definiciones de los Términos y Condiciones se aplican a esta Política de Privacidad, a menos que se indique lo contrario. Además de esta Política de Privacidad, es su obligación revisar los Términos y Condiciones que se incorporan aquí por referencia.',
    title16: '3. ¿Qué datos personales recopilamos?',
    text58: 'HBR recolecta, procesa y almacena sólo los datos personales que son estrictamente necesarios para el correcto funcionamiento del Sitio web y para mejorar la experiencia de los Usuarios en el mismo. Cualquier procesamiento adicional de los datos personales sólo se realiza sobre la base de otras obligaciones o permisos legales, o si Usted ha prestado su consentimiento expreso a HBR.',
    text59: 'HBR recolecta, procesa y almacena datos personales de la forma que se indica a continuación:',
    text60: 'Recolección y almacenamiento no automático de datos personales:',
    text61: 'Cuando Usted accede y/o usa el Sitio web entrega sus datos personales para determinadas finalidades, HBR recolecta, procesa y almacena estos datos personales. A continuación, los datos que se recolectan bajo esta modalidad:',
    text62: 'Correo electrónico del Usuario.',
    text63: 'Estos datos personales se recopilarán a través del Sitio web cuando el Usuario introduzca información en alguno de los campos destinados a tal efecto. No es necesario que Usted proporcione esta información para poder utilizar el Sitio web. Si elige proporcionar esta información, HBR la conservará en sus registros y la procesará siempre que Usted acepte el tratamiento de sus datos de acuerdo con las disposiciones de esta Política de Privacidad.',
    title17: '4. ¿Cuáles son las finalidades para procesar los datos personales?',
    text64: 'Los datos personales serán procesados por HBR con las siguientes finalidades:',
    text65: 'Ayudar a establecer y verificar la identidad del Usuario.',
    text66: 'Mantener la seguridad e integridad de los sistemas, instalaciones y personal de HBR.',
    text67: 'Prestar asistencia: comunicarse y responder a preguntas, consultas, comentarios, sugerencias o reclamos del Usuario, requerimientos de información al Usuario.',
    text68: 'Prestar asistencia técnica y soporte al Usuario.',
    text69: 'Enviar al Usuario actualizaciones y correos electrónicos o mensajes sobre ofertas de productos o servicios que son de su interés, avisos de promociones y otra información sobre productos, incluyendo comunicaciones con fines publicitarios e informativos, Usted podrá autorizar o no que HBR le envíe ofertas de marketing sobre productos o servicios, noticias o correos con novedades de la Empresa haciendo click en la casilla “autorizo el envío de publicidad”',
    text70: 'Mantener registros, como listas de correo o listas de tipos de Usuarios, por preferencias o características, con el objeto de mantener al Usuario informado sobre productos y servicios de su interés. HBR podrá elaborar perfiles en base a estudios de marketing y técnicas y procedimientos estadísticos y de segmentación que permitan personalizar la oferta de productos y servicios adecuados a las características y necesidades del Usuario.',
    text71: 'Mejorar el Sitio web tomando en cuenta las preferencias del Usuario.',
    title18: '5. ¿Quién más recibe los datos personales?',
    text72: 'Los datos personales también podrán ser compartidos con proveedores o terceros externos ("Receptores") para su correcto procesamiento, quienes procesarán los datos para las finalidades autorizadas y de acuerdo a las instrucciones de HBR. Los Receptores autorizados son: (i) terceros que prestan servicios a HBR para poder brindar los servicios de análisis, correo electrónico y verificación de datos; (ii) terceros que prestan servicios a HBR en relación con el funcionamiento del Sitio web; y (iii) auditores, contratistas u otros asesores que auditen cualquiera de los procesos comerciales de HBR.',
    text73: 'Cualquier procesamiento realizado por Receptores se regirá por un mandato o acuerdo de procesamiento de datos en la forma requerida por la ley, que se basa en su aceptación explícita, preservando los Usuarios todos y cada uno de sus derechos legales de protección de datos y obligando a los Receptores a respetar la información personal y manejarla de acuerdo con las leyes pertinentes. Los Receptores deben mantener la confidencialidad de dicha información y utilizar los datos personales solo en el curso de la prestación de los servicios prestados a HBR y solo para los fines que dicte HBR.',
    text74: 'De acuerdo a la legislación vigente, HBR también puede compartir los datos personales en casos especiales, tales como: (i) cumplir con la ley, regulación, citación, solicitud de una autoridad aplicable u orden judicial; (ii) detectar, prevenir o abordar el fraude, la seguridad, la infracción de sus políticas de seguridad o problemas técnicos; (iii) hacer cumplir las disposiciones de esta Política de Privacidad o cualquier otro acuerdo entre usted y HBR, incluida la investigación de posibles violaciones de la misma; (iv) proteger contra daños a los derechos, propiedad o seguridad de la Empresa, sus socios, las Compañías, Usuarios o el público.',
    title19: '6. Derechos del Usuario y retención',
    text75: 'El Usuario en cualquier momento, siempre que se cumplan los requisitos respectivos, puede hacer valer los siguientes derechos mediante el envío de una solicitud escrita dirigida a HBR, en la sección “Contacto” en el Sitio web, indicando en la referencia del e-mail “Política de Privacidad HBR”:',
    text76: 'Derecho de acceso: el Usuario tiene derecho a acceder a sus datos personales para conocer qué datos personales suyos se están procesando y cómo se procesan. En particular podrá pedir la siguiente información:',
    text77: 'Los datos tratados y su origen.',
    text78: 'La finalidad o finalidades del tratamiento.',
    text79: 'Las categorías, clases o tipos de destinatarios, o bien, la identidad de cada destinatario, en caso de solicitarlo así el Usuario, a los que se les hayan comunicado o cedido los datos o se prevea hacerlo.',
    text80: 'Derecho a pedir la rectificación de datos personales: el Usuario tiene derecho a pedir la rectificación y corrección de sus datos personales que considere inexactos, desactualizados o incompletos.',
    text81: 'Derecho a pedir la cancelación, borrado o eliminación de datos personales: el Usuario tiene derecho a solicitar la eliminación de sus datos personales en los siguientes casos:',
    text82: 'Cuando los datos no resulten necesarios en relación con los fines del tratamiento para el cual fueron recogidos.',
    text83: 'Cuando el Usuario haya revocado su consentimiento para el tratamiento y éste no tenga otro fundamento legal.',
    text84: 'Cuando los datos hayan sido obtenidos o tratados ilícitamente por HBR.',
    text85: 'Cuando se trate de datos caducos.',
    text86: 'Cuando los datos deban suprimirse para el cumplimiento de una sentencia judicial o de una obligación legal.',
    text87: 'Derecho a pedir la limitación o restricción del procesamiento de datos personales: el Usuario tiene derecho a solicitar la limitación del tratamiento de sus datos personales, por ejemplo, a sólo determinados fines.',
    text88: 'Derecho a oponerse u objetar el procesamiento de datos personales: el Usuario tiene derecho a oponerse a dicho procesamiento, en cualquier momento, si:',
    text89: 'El tratamiento afecta sus derechos y libertades fundamentales.',
    text90: 'El tratamiento se realiza exclusivamente con fines de mercadotecnia o marketing directo de bienes, productos o servicios.',
    text91: 'Derecho a retirar cualquiera de los consentimientos que haya otorgado, sin que ello afecte la licitud del tratamiento basado en el consentimiento previo a su retirada',
    text92: 'Si desea ejercer alguno de los derechos mencionados anteriormente comuníquese directamente en la sección “Contacto” en el Sitio web.',
    text93: 'Es probable que el Usuario que solicite algunas de estas acciones no pueda volver a usar el Sitio web con todas sus funcionalidades.',
    text94: 'Los datos personales se conservarán hasta que se cumpla el objetivo por el cual se recopilaron o hasta que el Usuario ejerza el derecho a la rectificación, cancelación y/u oposición de estos. Esto se cumplirá en la medida que no se entre en conflicto con la necesidad de mantener su almacenamiento, por motivos legales.',
    title20: '7. Base legal para el procesamiento',
    text95: 'El procesamiento de los datos personales es necesario para el cumplimiento de las obligaciones contractuales que podría tener HBR hacia Usted en caso de ser aplicable, para responder a sus consultas, comentarios, sugerencias, reclamos u otros similares, para proteger los intereses legítimos de la Empresa y para cumplir con nuestras obligaciones legales.',
    text96: 'Cuando HBR dependa de su consentimiento para procesar sus datos personales, Usted tiene derecho a rechazar el consentimiento en cualquier momento. Sin embargo, en caso de que la Empresa haya compartido sus datos personales personal en función de su consentimiento, para retirar su consentimiento deberá dirigirse a la entidad con la que sus datos fueron compartidos.',
    text97: 'Si tiene alguna pregunta o necesita más información sobre la base legal sobre la que recopilamos y usamos su información personal, comuníquese con nosotros usando nuestra página Contacto en el Sitio web.',
    title21: '8. Seguridad y confidencialidad',
    text98: 'HBR trata sus datos personales con estricta confidencialidad, y el servidor en el que se almacenarán estos datos incluye las medidas de seguridad estándar de la industria necesarias para evitar la destrucción, pérdida, alteración, divulgación o acceso no autorizados, accidentales o ilegales.',
    text99: 'En particular, HBR dispone de las siguientes herramientas y prácticas para procesar y almacenar los datos personales de los Usuarios de manera segura, los cuales lleva a cabo y verifica su servicio de hosting, a través de:',
    text100: '-Análisis de Ethical Hacking',
    text101: '-Mecanismos protectores de ataques DDoS',
    text102: '-Buenas prácticas de programación',
    text103: '-Certificados SSL',
    title22: '9. Enlaces a otros sitios web',
    text104: 'Esta Política de Privacidad se aplica solo al Sitio web y a los productos y/o servicios desarrollados por la HBR y que Usted contrate y acceda a través del Sitio web. La Política de Privacidad no se aplica a sitios web de terceros vinculados al Sitio web. Los sitios web o servicios de terceros pueden tener sus propias políticas de privacidad y le recomendamos que las lea detenidamente. La inclusión de un enlace a un sitio web o servicio de un tercero no es una recomendación del uso de dicho sitio web o servicio. Los sitios de terceros pueden contener información o servicios que son ilegales o que algunas personas pueden encontrar inapropiados u ofensivos. Usted reconoce y acepta que no tendremos responsabilidad alguna con respecto a dichos sitios de terceros y su uso de ellos.',
    title23: '10. Asignación',
    text105: 'Sujeto a la ley aplicable, HBR podrá transferir o ceder su información, sin previo aviso y sin necesidad de recibir su consentimiento, a cualquier tercero como resultado de un cambio de control, consolidación, fusión, adquisición o cualquier proceso de reorganización, local y/o transfronterizo.',
    title24: '11.  Actualizaciones de esta política',
    text106: 'Esta Política de Privacidad está sujeta a modificaciones. Consulte el título "Última revisión" en este documento para ver la fecha en la cual se actualizó por última vez. Cualquier cambio a la Política de Privacidad entrará en vigencia una vez que sea publicada en el Sitio web. En caso de cambios sustanciales en esta Política de Privacidad, HBR lo comunicará mediante un aviso en el Sitio web. Si el usuario no está de acuerdo con los cambios, deberá dejar de utilizar el Sitio Web.',
    text107: 'Última revisión: 12 de Julio de 2024.',
}