import Navbar from '../layout/Header';
import Footer from '../layout/Footer';
import ContentDetalle from './ContentDetalle';


const Detalle = () => {
  return (
    <div className='detalle imagen-mobile'>
        <Navbar/>
        <ContentDetalle/>
        <Footer/>
    </div>
  )
}

export default Detalle