import { ApiOutlined, CheckOutlined, CrownOutlined, FileSearchOutlined, HistoryOutlined, LeftOutlined, RocketOutlined } from '@ant-design/icons';
import { Button, Card, Col, Layout, Row, Space, Typography, Spin, Carousel, Flex } from 'antd'
import { useParams } from 'react-router';

//import Detalle from '../../assets/images/details.webp';
import detalleMobile from '../../assets/images/Mask group.svg';
import flechaMobile from '../../assets/images/flecha-mobile.svg';
import './Catalogo.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { detalleProductosApi, getDocumento } from '../../services/producto';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver'
import parse from 'html-react-parser';
import { separadorDeMiles } from '../../utils/Utils';


import Detalle from '../../assets/images/details.svg';
import Detalles from '../../assets/images/detalle.png';

const { Content } = Layout;
const { Title, Text } = Typography;

const ContentDetalle = () => {
  const language = useSelector(state => state.aplicacion.language);
  const navigate = useNavigate();
  const { id } = useParams()
  const [detalleProductos, setDetalleProductos] = useState(null)
  const [detalleTraduccion, setDetalleTraduccion] = useState(null)
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const informacionDetalleProducto = await detalleProductosApi(id);
        setDetalleProductos(informacionDetalleProducto)
      } catch (error) {
        console.log(error)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
    fetchData();
  }, [id])

  useEffect(() => {
    if (language && detalleProductos) {
      if (typeof detalleProductos === 'object' && Object.keys(detalleProductos).length > 0) {
        const traduccion = detalleProductos.idiomas.find(item => item.IdIdiomaTraducir === (language === 'es' ? 1 : 2));

        if (traduccion) {
          const { idiomas, ...detalleSinIdiomas } = detalleProductos;

          const detalleConTraduccion = {
            ...detalleSinIdiomas,
            ...traduccion,
          };

          setDetalleTraduccion(detalleConTraduccion);
        } else {
          console.error('No se encontró traducción para el idioma seleccionado');
        }
      } else {
        console.error('detalleProductos no es un objeto o está vacío');
      }
    }
  }, [language, detalleProductos]);

  const onDownloadDocumento = async (idProducto, idTipoDocumento) => {
    try {
      const docRes = await getDocumento(idProducto, idTipoDocumento)
      if (docRes) {
        const blobFile = new Blob(
          [docRes.buffer],
          { type: docRes.contentType }
        )
        saveAs(blobFile, docRes.fileName)
      } else {
        console.log("Respuesta nula")
      }
    } catch (error) {
      console.error("Error al descargar documento")
      console.error(error)

    } finally {
    }

  }

  const getPluralText = (years) => {
    if (years === 1) {
      return language === 'es' ? 'año' : ' year historicity';
    } else {
      return language === 'es' ? 'años' : ' years historicity ';
    }
  };

  const handleExterlnalRoute = () => {
    window.location.href = process.env.REACT_APP_URL_ONBOARDING_PUBLICO
  }



  return (
    <Content className=''>
      {!detalleProductos ? <div style={{ minHeight: '100vh' }}> <Spin className='spinLoad' size='large' style={{ color: '#fff' }} /> </div> :
        <div className='contenido-detalle'>
          <Row gutter={0}>
            <Col xs={24} sm={24} md={12} style={{ marginTop: 50, textAlign: 'left' }} className='estilo-detalle'>
              <Title level={1} style={{ color: '#fff', fontSize: 50 }} className='titulo-detalle-producto'> {detalleTraduccion?.NombreProducto}</Title>
              <Title level={4} style={{ color: '#fff' }} className='subtitulo-detalle-producto'> {t('details_description_subtitle')}</Title>
              <Text style={{ position: 'relative', top: -12 }}>
                <span className='detalleProducto' style={{ color: "#fff"}}>
                  {parse(detalleTraduccion?.DescripcionDetalleProducto ?? "")}
                </span>
              </Text>
              <Row>
                <Col xs={0} sm={0} md={24}>
                  <Button type='default'
                    shape='round'
                    onClick={() => navigate('/nuestras-apis')}
                    icon={<LeftOutlined />}>
                    {t('details_button_return')}
                  </Button>
                  <Button type='primary'
                    style={{ marginLeft: 10 }}
                    shape='round'
                    icon={<FileSearchOutlined />}
                    onClick={() => onDownloadDocumento(id, 1)}>
                    {t('details_button_contract')}
                  </Button>
                  <Button type='primary'
                    style={{ marginLeft: 10 }}
                    shape='round'
                    icon={<FileSearchOutlined />}
                    onClick={() => onDownloadDocumento(id, 3)}>
                    {t('details_button_doc')}
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={0}>
                  <Button type='default'
                    shape='round'
                    onClick={() => navigate('/nuestras-apis')}
                    icon={<LeftOutlined />}>
                    {t('details_button_return')}
                  </Button>
                  <Button type='primary'
                    style={{ marginTop: 10 }}
                    shape='round'
                    icon={<FileSearchOutlined />}
                    onClick={() => onDownloadDocumento(id, 1)}>
                    {t('details_button_contract')}
                  </Button>
                  <Button type='primary'
                    style={{ marginTop: 10 }}
                    shape='round'
                    icon={<FileSearchOutlined />}
                    onClick={() => onDownloadDocumento(id, 2)}>
                    {t('details_button_doc')}
                  </Button>
                </Col>
              </Row>

            </Col>
            <Col xs={0} sm={0} md={2} style={{ marginTop: 60 }}>
            </Col>
            <Col xs={0} sm={0} md={6} style={{ marginTop: 60 }}>
              <img src={Detalles} alt='logo' style={{ width: 500 }}/>
            </Col>
          </Row>
          <Row gutter={0}>
            <Col xs={21} sm={22} md={14} style={{ textAlign: 'left' }}>
              <Title level={3} style={{ color: '#fff' }}>{t('details_types_plans')}</Title>
              <br /><br />
            </Col>
            <Col xs={3} sm={2} md={0} style={{ marginTop: 50 }}>
              <img src={flechaMobile} alt='flecha'/>
            </Col>
          </Row>
          <Row gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}>
           
            {detalleProductos && detalleProductos.planesProducto && detalleProductos.planesProducto.map(plan => (
               <Col xs={0} sm={0} md={8} >
               <Flex wrap="wrap" gap="middle" justify="left" >
                <Card className='card-detail-prod' bordered={false} style={{ width: 340, position: 'relative' }}>
                  <div className="list-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                          {plan.nombrePlan === "Básico" && (<RocketOutlined style={{ fontSize: 22, color: '#07FDEB' }} />)}
                          {plan.nombrePlan === "Starter" && (<RocketOutlined style={{ fontSize: 22, color: '#07FDEB' }} />)}
                          {plan.nombrePlan === "Developer" && (<ApiOutlined style={{ fontSize: 22, color: '#07FDEB' }} />)}
                          {plan.nombrePlan === "Advance" && (<CrownOutlined style={{ fontSize: 22, color: '#07FDEB' }} />)}
                      <div style={{ lineHeight: 0, marginLeft: 8 }}>
                        <p style={{ fontWeight: 'bold', color: '#07FDEB', textAlign: 'left', fontSize: 18 }}>
                          {plan?.nombrePlan === 'Básico' ? t('details_name_plan_free') : plan?.nombrePlan}
                        </p>
                        {plan.nombrePlan === "Básico" && (<p style={{ color: '#07FDEB', fontSize: 10 }}>{t('details_cards_subtitle0')}</p>)}
                        {plan.nombrePlan === "Starter" && (<p style={{ color: '#07FDEB', fontSize: 10 }}>{t('details_cards_subtitle1')}</p>)}
                        {plan.nombrePlan === "Developer" && (<p style={{ color: '#07FDEB', fontSize: 10 }}>{t('details_cards_subtitle2')}</p>)}
                        {plan.nombrePlan === "Advance" && (<p style={{ color: '#07FDEB', fontSize: 10 }}>{t('details_cards_subtitle3')}</p>)}
                      </div>
                    </div>
                    <div className='precio-tablet' style={{ lineHeight: 0, marginLeft: 8 }}>
                      <p style={{ fontWeight: 'bold', color: '#07FDEB', fontSize: 20 }}>UF {plan?.precio} /{t('details_cards_month')}</p>
                    </div>
                  </div>
                  <div style={{ color: '#fff' }}>
                    <p style={{ textDecoration: 'underline', textAlign: 'left', fontWeight: 'bold' }}>{t('details_products_plan')}</p>
                    <p style={{ textAlign: 'left' }}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /> { separadorDeMiles(plan?.cantidadRequest)} {plan?.nombrePlan === 'Básico' ? t('details_cards_requestFree') : t('details_cards_request')}</p>
                    
                    
                    <Row gutter={0}>
                      <Col span={2}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /></Col>
                      <Col span={22} style={{ marginLeft: -5 }} >{detalleTraduccion?.Atributo3}</Col>
                    </Row>
                  
                  {plan?.nombrePlan === "Básico" ? ('') : (<p style={{ textAlign: 'left' }}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /> {t('delay')}</p>)}
                  {plan?.nombrePlan === "Básico" ? (<p style={{ textAlign: 'justify' }}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /> Data End of day</p>) : (<p style={{ textAlign: 'left' }}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /> {t('instruments')}</p>)}
                  {plan?.nombrePlan === "Básico" ? (<p style={{ textAlign: 'left' }}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /> 10 {t('details_cards_instruments')}</p>) :  null }
                  <p style={{ textAlign: 'left' }}>
                    <CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} />
                    {language === 'es' ? (
                      <>
                        {' '}{ t('details_cards_historicity_part1')} { plan?.infoHistorica } { getPluralText(plan?.infoHistorica)}
                      </>
                    ) : (
                      <>
                        {' '}{ plan?.infoHistorica } { getPluralText(plan?.infoHistorica)}
                      </>
                    )}
                  </p>
                  </div>
                  <Row>
                    <Col span={24}>
                      <Space direction='vertical' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 30 }}>
                        {plan?.nombrePlan === 'Básico' ? (<Button style={{ width: '120%' }} className="btnContratar" type='text' shape='round' onClick={() => window.open(process.env.REACT_APP_URL_ONBOARDING_PUBLICO, "_blank")}>{t('details_cards_button1')}</Button>) : (<Button className="btnContratar" style={{ width: '120%' }} type='text' shape='round' onClick={() => window.open(process.env.REACT_APP_URL_ONBOARDING_PUBLICO, "_blank")}>{t('details_cards_button2')}</Button>)}
                      </Space>
                    </Col>
                  </Row>
                </Card>
                </Flex>
            </Col>
            ))}
            
          </Row>
          <Row>
            <Col xs={24} sm={24} md={0}>
              <Carousel>
              {detalleProductos && detalleProductos.planesProducto && detalleProductos.planesProducto.map(plan => (
                <Col xs={0} sm={0} md={8} >
                <Flex wrap="wrap" gap="middle" justify="left" >
                  <Card className='card-detail-prod' bordered={false} style={{ width: 340, position: 'relative', marginLeft: 5, marginRight: 5 }}>
                    <div className="list-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                            {plan.nombrePlan === "Básico" && (<RocketOutlined style={{ fontSize: 22, color: '#07FDEB' }} />)}
                            {plan.nombrePlan === "Starter" && (<RocketOutlined style={{ fontSize: 22, color: '#07FDEB' }} />)}
                            {plan.nombrePlan === "Developer" && (<ApiOutlined style={{ fontSize: 22, color: '#07FDEB' }} />)}
                            {plan.nombrePlan === "Advance" && (<CrownOutlined style={{ fontSize: 22, color: '#07FDEB' }} />)}
                        <div style={{ lineHeight: 0, marginLeft: 8 }}>
                          <p style={{ fontWeight: 'bold', color: '#07FDEB', textAlign: 'left', fontSize: 18 }}>
                            {plan?.nombrePlan === 'Básico' ? t('details_name_plan_free') : plan?.nombrePlan}
                          </p>
                          {plan.nombrePlan === "Básico" && (<p style={{ color: '#07FDEB', fontSize: 10 }}>{t('details_cards_subtitle0')}</p>)}
                          {plan.nombrePlan === "Starter" && (<p style={{ color: '#07FDEB', fontSize: 10 }}>{t('details_cards_subtitle1')}</p>)}
                          {plan.nombrePlan === "Developer" && (<p style={{ color: '#07FDEB', fontSize: 10 }}>{t('details_cards_subtitle2')}</p>)}
                          {plan.nombrePlan === "Advance" && (<p style={{ color: '#07FDEB', fontSize: 10 }}>{t('details_cards_subtitle3')}</p>)}
                        </div>
                      </div>
                     
                     {language === 'es' ? (
                       <div className='precio-tablet' style={{ lineHeight: 0, marginLeft: 8 }}>
                       <p style={{ fontWeight: 'bold', color: '#07FDEB', fontSize: 17 }}>UF {plan?.precio} /{t('details_cards_month')}</p>
                     </div>
                     ) : (
                      <div className='precio-tablet' style={{ lineHeight: 0, marginLeft: 8 }}>
                      <p style={{ fontWeight: 'bold', color: '#07FDEB', fontSize: 11 }}>UF {plan?.precio} /{t('details_cards_month')}</p>
                    </div>
                     )}
                     
                    </div>
                    <div style={{ color: '#fff' }}>
                      <p style={{ textDecoration: 'underline', textAlign: 'left', fontWeight: 'bold' }}>{t('details_products_plan')}</p>
                      <p style={{ textAlign: 'left' }}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /> { separadorDeMiles(plan?.cantidadRequest)} {plan?.nombrePlan === 'Básico' ? t('details_cards_requestFree') : t('details_cards_request')}</p>
                      
                      
                      <Row gutter={0}>
                        <Col span={2}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /></Col>
                        <Col span={22} style={{ }} >{detalleTraduccion?.Atributo3}</Col>
                      </Row>
                    
                    {plan?.nombrePlan === "Básico" ? ('') : (<p style={{ textAlign: 'left' }}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /> {t('delay')}</p>)}
                    {plan?.nombrePlan === "Básico" ? (<p style={{ textAlign: 'justify' }}><Row gutter={0}><Col span={2}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /></Col><Col span={22}> Data End of day</Col></Row></p>) : (<p style={{ textAlign: 'left' }}><Row gutter={0}><Col span={2}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /></Col><Col span={22}> {t('instruments')}</Col></Row></p>)}
                    {plan?.nombrePlan === "Básico" ? (<p style={{ textAlign: 'left' }}><CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} /> 10 {t('details_cards_instruments')}</p>) :  null }
                    <p style={{ textAlign: 'left' }}>
                      <CheckOutlined style={{ color: 'rgb(7, 253, 235)' }} />
                      {language === 'es' ? (
                        <>
                          {' '}{ t('details_cards_historicity_part1')} { plan?.infoHistorica } { getPluralText(plan?.infoHistorica)}
                        </>
                      ) : (
                        <>
                          {' '}{ plan?.infoHistorica } { getPluralText(plan?.infoHistorica)} asedas
                        </>
                      )}
                    </p>
                    </div>
                    <Row>
                      <Col span={24}>
                        <Space direction='vertical' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 30 }}>
                          {plan?.nombrePlan === 'Básico' ? (<Button style={{ width: '120%' }} className="btnContratar" type='text' shape='round' onClick={() => window.open(process.env.REACT_APP_URL_ONBOARDING_PUBLICO, "_blank")}>{t('details_cards_button1')}</Button>) : (<Button className="btnContratar" style={{ width: '120%' }} type='text' shape='round' onClick={() => window.open(process.env.REACT_APP_URL_ONBOARDING_PUBLICO, "_blank")}>{t('details_cards_button2')}</Button>)}
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                  </Flex>
              </Col>
              ))}
              </Carousel>
            </Col>
          </Row>
        </div>}
      <br />
    </Content>
  )
}

export default ContentDetalle