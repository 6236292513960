import { Button, Card, Typography } from 'antd';
import React from 'react'
import Slider from 'react-slick';
import { saveAs } from 'file-saver'
import { FileSearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getDocumento, getGuiaInicio } from '../../services/producto';
import { TipoDocumentoId } from '../../enum/enums';
import parse from 'html-react-parser';


import inscripcion from '../../assets/images/inscripcion.png';
import apis from '../../assets/images/apis.png';
import request from '../../assets/images/request.png';
import lupa from '../../assets/images/analisis.png'
import tablet from '../../assets/images/registro.png'
import key from '../../assets/images/key.png'
import mano from '../../assets/images/engranaje.png'


const { Text, Title } = Typography;
const SliderApisMobile = ({ t, i18n, productosApi }) => {
    const idDocumentoApi = 1
    

    const onRedirect = () => {
        window.scrollTo(0, 0)
    }

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
          />
        );
      }
      
      const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
          />
        );
      }

    const getTextoIdioma = (item, atributo) => {

        if (item && item.IdIdiomaTraducir && item.IdIdiomaTraducir.length > 0) {
          const idIdiomaTraducirAux = i18n.language === "es" ? 1 : 2
          const traduccion = item.IdIdiomaTraducir.find(x => x.IdIdiomaTraducir === idIdiomaTraducirAux)
          if (traduccion) {
            return traduccion[atributo]
          }
        }
        return ""
    }

    const onDownloadDocumento = async (idProducto, idTipoDocumento) => {

        try {
          const docRes = await getDocumento(idProducto, idTipoDocumento)
          if (docRes) {
            const blobFile = new Blob(
              [docRes.buffer],
              { type: docRes.contentType }
            )
            saveAs(blobFile, docRes.fileName)
          } else {
            console.log("Respuesta nula")
          }
        } catch (error) {
          console.error("Error al descargar documento")
          console.error(error)
    
        } finally {
        }
    
    }

    const customSort = (a, b) => {
        const orderMap = {
          "api-renta-variable-rv": 1,
          "api-renta-fija-rf": 2,
          "api-intermediacion-financiera": 3,
        };
      
        return orderMap[a.CodigoProducto] - orderMap[b.CodigoProducto];
    };

    const handleExternalLink = (url) => {
        window.open(url, "_blank");
      }

    const settings = {
        className: "",
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    
    const onDownloadGuiaInicio = async () => {
      try {
        const docRes = await getGuiaInicio()
        if (docRes) {
          const blobFile = new Blob(
            [docRes.buffer],
            { type: docRes.contentType }
          )
          saveAs(blobFile, docRes.fileName)
        } else {
          console.log("Respuesta nula")
        }
      } catch (error) {
        console.error("Error al descargar documento")
        console.error(error)
  
      } finally {
      }
  
    }
    
    return (
        <div className="slider-container" >
          <Slider {...settings}>
            <div>
            <Card className='card-carrousel' style={{ marginLeft: 40, marginRight: 40, height: 480 }}>
                <img src={lupa} alt='lupa' style={{ width: 150, height: 150 }} />
                <div className="card-content">
                    <Title level={4} style={{ color: '#fff' }}>{t('how_does_it_work_card1_title')}</Title>
                    <Text style={{ color: '#fff' }}>{t('how_does_it_work_card1_description')}<Link style={{ color: 'rgb(7, 253, 235)', textDecoration: 'underline' }} to="/nuestras-apis">{t('how_does_it_work_card1_description_link')}</Link>{t('how_does_it_work_card1_description_2')}</Text>
                </div>
            </Card>
            </div>
            <div>
            <Card className='card-carrousel' style={{ marginLeft: 40, marginRight: 40, height: 480 }}>
                <img src={tablet} alt='tablet' style={{ width: 150, height: 150 }} />
                <div className="card-content">
                    <Title level={4} style={{ color: '#fff' }}>{t('how_does_it_work_card2_title')}</Title>
                    <Text style={{ color: '#fff' }}>{t('how_does_it_work_card2_description')}</Text>
                </div>
            </Card>
            </div>
            <div>
            <Card className='card-carrousel' style={{ marginLeft: 40, marginRight: 40, height: 480 }}>
                <img src={request} alt='request' style={{ width: 150, height: 150 }} />
                <div className="card-content">
                    <Title level={4} style={{ color: '#fff' }}>{t('how_does_it_work_card3_title')}</Title>
                    <Text style={{ color: '#fff' }}>{t('how_does_it_work_card3_description')}</Text>
                </div>
            </Card>
            </div>
            <div>
            <Card className='card-carrousel' style={{ marginLeft: 40, marginRight: 40, height: 480 }}>
                <img src={mano} alt='mano' style={{ width: 150, height: 150 }} />
                <div className="card-content">
                    <Title level={4} style={{ color: '#fff' }}>{t('how_does_it_work_card4_title')}</Title>
                    <Text style={{ color: '#fff', fontSize: 15 }}>{parse(t('how_does_it_work_card4_description'))} <a
                    href={`${process.env.REACT_APP_FRONTEND_URL}/openapi/20`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.preventDefault();
                        handleExternalLink(e.currentTarget.href);
                    }}
                    style={{ color: 'rgb(7, 253, 235)', textDecoration: 'underline' }}
                    >{t('how_does_it_work_card4_description_link')}</a>{t('how_does_it_work_card4_description_2')}</Text>
                </div>
            </Card>
            </div>
            <div>
            <Card className='card-carrousel' style={{ marginLeft: 40, marginRight: 40, height: 480 }}>
                <img src={key} alt='key' style={{ width: 150, height: 130 }} />
                <div className="card-content">
                    <Title level={4} style={{ color: '#fff' }}>{t('how_does_it_work_card5_title')}</Title>
                    <Text style={{ color: '#fff' }}>{t('how_does_it_work_card5_description')}</Text>
                </div>
            </Card>
            </div>
            <div>
            <Card className='card-carrousel' style={{ marginLeft: 40, marginRight: 40, height: 480 }}>
                <img src={apis} alt='apis' style={{ width: 150, height: 150 }} />
                <div className="card-content">
                    <Title level={4} style={{ color: '#fff' }}>{t('how_does_it_work_card6_title')}</Title>
                    <Text style={{ color: '#fff' }}>{t('how_does_it_work_card6_description')}</Text>
                </div>
            </Card>
            </div>
            <div>
            <Card className='card-carrousel' style={{ marginLeft: 40, marginRight: 40, height: 480 }}>
                <img src={inscripcion} alt='inscripcion' style={{ width: 150, height: 130 }} />
                <div className="card-content">
                    <Title level={4} style={{ color: '#fff' }}>{t('how_does_it_work_card7_title')}</Title>
                    <Text style={{ color: '#fff' }}>{t('how_does_it_work_card7_description')}</Text>
                </div>
            </Card>
            </div>
            <div>
            <Card style={{ marginLeft: 40, marginRight: 40 }}>
                <div style={{ textAlign: 'left' }}>
                    <Title level={4} style={{ color: '#fff', fontSize: 30, marginTop: 0 }}>
                        {t('how_does_it_work_card8_title')}
                    </Title>
                    <div style={{ textAlign: 'left', marginBottom: '10px' }}>
                        <Text style={{ color: '#fff', fontSize: 15 }}>
                            {t('how_does_it_work_card8_description')}
                        </Text>
                        <br />
                        <br />
                        <Button style={{ backgroundColor: '#fff', color: '#000' }}
                        shape='round'
                        onClick={() => onDownloadGuiaInicio()}>
                        <FileSearchOutlined /> {t('how_does_it_work_card8_btn1')}
                        </Button>
                    </div>
                        {productosApi.map((p, indexPack) => {
                            // TODO - Evitar renderizados
                        return (
                        <React.Fragment key={`pack-${indexPack}`}>
                            <div>
                            <br />
                            <Text style={{ color: '#07fdeb', fontWeight: 'bold' }}>
                                {getTextoIdioma(p, "NombreProducto")}
                            </Text>
                            <br />
                            </div>
                                {p.ProductosAsociados.sort(customSort).map((x, indexApi) => {
                                return (
                                    <React.Fragment key={`pack-${indexApi}`}>
                                    <div>
                                    {x.IdProducto !== 20 && x.IdProducto !== 19 && (
                                    <Text style={{ color: '#fff' }}>
                                        {getTextoIdioma(x, "NombreProducto")}
                                    </Text>
                                    )}
                                    </div>
                                    <div style={{ textAlign: 'left', marginBottom: '10px' }}>
                                        <br />
                                        <Button
                                        style={{ backgroundColor: '#fff', color: '#000' }}
                                        shape='round'
                                        onClick={() => onDownloadDocumento(x.IdProducto, TipoDocumentoId.DOCUMENTACION)}
                                        >
                                        <FileSearchOutlined />
                                        {t('how_does_it_work_card8_btn2')}
                                        </Button>
                                        <Button
                                        style={{ backgroundColor: '#fff', color: '#000', marginLeft: 20 }}
                                        shape='round'
                                        href={x.URLProducto}
                                        target="_blank"
                                        >
                                        {t('how_does_it_work_card8_btn3')}
                                        </Button>
                                    </div>
                                    </React.Fragment>
                                );
                                })}
                        </React.Fragment>
                        )
                        })}

                    </div>
                    <div style={{ color: '#fff', textAlign: 'left', fontSize: 15, marginTop: 30 }}>
                        {t('how_does_it_work_card8_text_help1')}
                        <Link style={{ color: 'rgb(7, 253, 235)', textDecoration: 'underline' }} to={'/contacto'} onClick={onRedirect}>{t('how_does_it_work_card8_text_help2')}</Link>
                        {t('how_does_it_work_card8_text_help3')}
                    </div>
                </Card>
            </div>
          </Slider>
        </div>
      );
}

export default SliderApisMobile