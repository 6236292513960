import { Button, Card, Col, Collapse, Form, Input, Layout, Row, Select, Space, Typography } from 'antd';
import { UpOutlined, MailOutlined, QuestionCircleOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
import './Contacto.scss';
import { useEffect, useState } from 'react';
import ModalEnvioMsj from '../modal/ModalEnvioMsj';
import { envioCorreo } from '../../services/contacto';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const ContentContacto = ({ t, i18n }) => {

    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState([]);
    const [openModal, setOpenModal] = useState(false)

    const [disabledSave, setDisabledSave] = useState(true);
    const [loading, setLoading] = useState(false)


    const handlePanelChange = (key) => {
        setActiveKey(key);
    };


    const onFinish = async (values) => {
        try {
        setLoading(true)
            if (values) {
                const result = await envioCorreo(values);
                console.log("Resultado:", result);
                setOpenModal(true);
                form.resetFields();
            }
        } catch (error) {
            setLoading(false)
            throw new Error('error');
        }
        finally{
            setLoading(false)
        }
    };

    const validateEmail = (rule, value) => {
        if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject('Ingresa un correo electrónico válido');
    };

    const handleFormChange = (_, allValues) => {
        const allFieldsFilled = Object.values(allValues).every(value => value);
        setDisabledSave(!allFieldsFilled);
    };


    return (
        <Content className='content'>
            <div className='content-contacto'>
                <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={24} sm={24} md={7}>
                        <div style={{ textAlign: 'left', marginTop: 100 }} className='info-contacto'>
                            <Title level={1} ><span style={{ color: '#fff' }}> {parse(t('contact_title_part1'))} </span>
                                <br /><span style={{ color: '#FFC000' }}>{t('contact_title_part2')}</span></Title>
                            <Text style={{ color: '#fff' }}><span>{t('contact_description_1')}</span><br /><span>{t('contact_description_2')}</span></Text>
                        </div>
                    </Col>
                    <Col md={2}></Col>
                    <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={12} className='cardhablemos'>
                        <Card>
                            <Title level={1} style={{ color: '#fff', textAlign: 'left', marginTop: 0 }}>{t('contact_form_title')}</Title>
                            <Form form={form} onFinish={onFinish} layout="vertical" onValuesChange={handleFormChange}>
                                <Row gutter={[16, 16]}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} >
                                        <Form.Item
                                            name="motivo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('contact_Error_message_1'),
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder={t('contact_placeholder_select')}
                                                suffixIcon={<QuestionCircleOutlined />}
                                                options={[
                                                    { value: 'consulta', label: t('contact_form_select1') },
                                                    { value: 'sugerencia', label: t('contact_form_select2') },
                                                    { value: 'ayuda', label: t('contact_form_select3') },
                                                    { value: 'otro', label: t('contact_form_select4') },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[16, 16]}>
                                    <Col className="gutter-row" xs={24} sm={24} md={10} >
                                        <Form.Item
                                            name="correo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Ingresa tu correo electrónico',
                                                },
                                                {
                                                    validator: validateEmail,
                                                }
                                            ]}
                                        >
                                            <Space.Compact block>
                                                <Button style={{ backgroundColor: '#fff', color: '#000' }}><MailOutlined /></Button>
                                                <Input style={{ width: '80%' }} placeholder={t('contact_Error_message_2')} />
                                            </Space.Compact>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={14} >
                                        <Form.Item
                                            name="mensaje"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Ingresa un mensaje'
                                                }
                                            ]}
                                        >
                                            <Input.TextArea rows={3} placeholder={t('contact_placeholder_input_message')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col className="gutter-row" style={{ marginTop: 10 }}>
                                        <Button 
                                        shape='round' 
                                        htmlType="submit" 
                                        className="btn-mensaje" 
                                        style={{ fontWeight: 'bold', width: '140%' }} 
                                        disabled={disabledSave}
                                        >
                                            {t('contact_form_btn')} {loading && <LoadingOutlined />}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginTop: 50 }}>
                    <Col xs={24} sm={24} md={24} style={{ textAlign: 'left' }}>
                        <Title level={2} style={{ color: '#fff' }}>{t('contact_frequently_asked_questions_title')}</Title>
                        <Row gutter={[16, 16]}>
                            <Col className="gutter-row" xs={24} sm={24} md={12} >
                                <Text style={{ color: '#fff' }}>{t('contact_frequently_asked_questions_subtitle')}</Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className='collapse-questions' style={{ marginTop: -50 }}>
                    <Row gutter={[16, 16]}>
                        <Col className='gutter-row cardpreguntas' xs={24} sm={24} md={24} xl={24} xxl={23} style={{ marginTop: 80 }} >
                            <Card>
                                <Collapse
                                    activeKey={activeKey}
                                    onChange={handlePanelChange}
                                    accordion
                                    expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
                                    expandIconPosition="end"
                                    bordered={false}
                                
                                >
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title1')}</span>
                                            </div>
                                        }
                                        key="21"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text1')}</p>
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text2')}
                                        </p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title2')}</span>
                                            </div>
                                        }
                                        key="263"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text4')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title3')}</span>
                                            </div>
                                        }
                                        key="22"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text5')}</p>
                                        <p style={{ color: '#fff', textAlign: 'left' }}>
                                            •	{t('frequently_asked_questions_text6')}
                                            <br />
                                            •	{t('frequently_asked_questions_text7')}
                                            <br />
                                            •	{t('frequently_asked_questions_text8')}
                                        </p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title4')}</span>
                                            </div>
                                        }
                                        key="23"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text9')}</p>
                                      
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text91')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title5')}</span>
                                            </div>
                                        }
                                        key="24"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text10')}</p>
                                        
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text11')}</p>
                                           
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text12')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title6')}</span>
                                            </div>
                                        }
                                        key="25"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text13')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title7')}</span>
                                            </div>
                                        }
                                        key="26"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text14')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title8')}</span>
                                            </div>
                                        }
                                        key="27"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text15')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title9')}</span>
                                            </div>
                                        }
                                        key="28"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text16')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title10')}</span>
                                            </div>
                                        }
                                        key="29"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text17')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title11')}</span>
                                            </div>
                                        }
                                        key="211"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text18')}</p>
                                    </Panel>
                                    <Panel
                                        header={
                                            <div>
                                                <span style={{ display: 'flex' }}>{t('frequently_asked_questions_title12')}</span>
                                            </div>
                                        }
                                        key="212"
                                    >
                                        <p style={{ color: '#fff', textAlign: 'left' }}>{t('frequently_asked_questions_text19')}</p>
                                    </Panel>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <ModalEnvioMsj isModalOpen={openModal} onClose={() => setOpenModal(false)}/>
        </Content>
    )
}

export default ContentContacto