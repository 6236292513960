import { Layout } from 'antd'
import Navbar from '../layout/Header';
import ContentCatalogo from './ContentCatalogo';
import Footer from '../layout/Footer';
import { useTranslation } from 'react-i18next';

const Catalogo = () => {

  const { t, i18n } = useTranslation();

  return (
    <Layout className='catalogo imagen-mobile' id='catalogo'>
        <Navbar/>
        <ContentCatalogo  t={t} i18n={i18n}/>
        <Footer/>
    </Layout>
  )
}

export default Catalogo