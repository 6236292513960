import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer, Typography, Row, Col, Avatar } from 'antd';
import './Layout.scss';
import { Link, useLocation } from 'react-router-dom';
import { GlobalOutlined, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobile, setLanguage } from '../../redux/reducers/AppReducer';
import logoApi from '../../assets/logos/1 LOGO API horizontal con isotipo blanco.png';


const { Header } = Layout;
const { Text } = Typography;

const Headers = () => {

  const language = useSelector(state => state.aplicacion.language)
  const isMobile = useSelector(state => state.aplicacion.isMobile)

  const dispatch = useDispatch()

  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [windowMobile, setWindowMobile] = useState(window.innerWidth < 768);
  const [collapsed, setCollapsed] = useState(isMobile);
  const [drawerVisible, setDrawerVisible] = useState(false);

  //const [languages, setLanguage] = useState(storedLanguage || 'es');

  const [activeItem, setActiveItem] = useState(1);

  useEffect(() => {
    i18n.changeLanguage(language)
    //localStorage.setItem('language', languages);

    dispatch(setLanguage(language))
  }, [dispatch, i18n, language])

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const handleLanguageChange = () => {
    const newLanguage = language === 'en' ? 'es' : 'en';
    dispatch(setLanguage(newLanguage));
  };

  const languageSwitch = (
    <div className="language-switch" onClick={handleLanguageChange}>
      <GlobalOutlined className="icon" />
      <Text className={`language-text ${language === 'es' ? 'selected' : ''}`}>Esp</Text>
      <span className="divider">/</span>
      <Text className={`language-text ${language === 'en' ? 'selected' : ''}`}>Eng</Text>
    </div>
  );

  const items = [
    {
      label: <Link to="/home">{t('home')}</Link>,
      id: 1,
      pathnames: ['/home', '/'],
    },
    {
      label: <Link to="/nuestras-apis">{t('our_apis')}</Link>,
      id: 2,
      pathnames: ['/nuestras-apis', '/detalle-producto'],
    },
    {
      label: <Link to="/como-funciona">{t('how_does_it_work')}</Link>,
      id: 3,
      pathnames: ['/como-funciona'],
    },
    {
      label: <Link to="/contacto">{t('contact')}</Link>,
      id: 4,
      pathnames: ['/contacto'],
    },
  ];

  return (
    <Header
      style={{
        
      }}
      className='header-mobile'
    >
      <div className="logo">
        <Link to="/home"><img src={logoApi} alt="logo" width={180} style={{ marginTop: 15 }} /></Link>
      </div>

      <Row  className='menu-tablet'>
        {/* menu desktop */}
        <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
        <Menu theme="dark" mode="horizontal" className="menu">
            {items.map((item) => (
              <Menu.Item
                key={item.id}
                className={`menu-item ${
                  (item.pathnames.some((pathname) =>
                    location.pathname.startsWith(pathname)
                  ) &&
                    !item.pathnames.includes('/home') &&
                    !item.pathnames.includes('/')) ||
                  item.pathnames.includes(location.pathname)
                    ? 'ant-menu-item-selected'
                    : ''
                }`}
              >
                {item.label}
              </Menu.Item>
            ))}
            <Menu.Item className="cambio-lenguaje" key={`lang-1`}>
              {languageSwitch}
            </Menu.Item>
          </Menu>
        </Col>

        {/* menu mobile */}
        <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
          <div className="menu-icon-circle" onClick={showDrawer}>
            <Avatar size={40} shape='circle' style={{ backgroundColor: '#1A1F24', borderColor: 'white' }}><MenuOutlined className="menu-icon" /></Avatar>
          </div>
          
          <Drawer
            title={
              <>
                <span style={{ fontSize: 30 }}>Menú</span>
                {languageSwitch}
              </>
            }
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={drawerVisible}
            width={250}
            className='menudrawer'
          >
            <Menu theme="dark" mode="vertical" className="menu">
              {items.map((item, index) => (
                <Menu.Item
                key={item.id}
                className={`menu-item ${
                  (item.pathnames.some((pathname) =>
                    location.pathname.startsWith(pathname)
                  ) &&
                    !item.pathnames.includes('/home') &&
                    !item.pathnames.includes('/')) ||
                  item.pathnames.includes(location.pathname)
                    ? 'ant-menu-item-selected'
                    : ''
                }`}
              >
                {item.label}
              </Menu.Item>
              ))}
            </Menu>
          </Drawer>
        </Col>
      </Row>
    </Header>
  );
};

export default Headers;

