import { CheckCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Modal, Row } from 'antd'
import React from 'react'
import { t } from 'i18next';

const ModalEnvioMsj = ({ isModalOpen, onClose }) => {
  return (
    <div>
            <Modal
                closable={false}
                centered
                open={isModalOpen}
                onOk={onClose}
                width={450}
                footer={[]
                }            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Avatar style={{ backgroundColor: '#07FDEB' }} size={80} icon={<CheckCircleOutlined />} />
                    <Row>
                        <Col span={24}>
                            <p>{t('modal_message_1')}</p>
                            
                            <p>{t('modal_message_2')}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button key="submit" type="primary" shape="round" style={{ backgroundColor: '#07FDEB !important' }} onClick={onClose}>
                            {t('modal_message_3')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
  )
}

export default ModalEnvioMsj