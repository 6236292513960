import AppRoutes from './routing/Routing';
import './assets/styles/antLayout.scss';
import "slick-carousel/slick/slick.scss";


function App() {
  return (
    
      <div className='react-content'>
          <AppRoutes/>
      </div>
  );
}

export default App;
