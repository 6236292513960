import { Col, Layout, Row, Typography, Menu, Space, Button, Flex } from 'antd';
import Face from '../../assets/images/facebook.svg';
import ind from '../../assets/images/instagram.svg';
import Insta from '../../assets/images/insta.svg'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ModalTerminosCondiciones from '../modal/ModalTerminosCondiciones';
import { useState } from 'react';

const { Footer } = Layout
const FooterLanding = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  }

  const onRedirect = (link) => {
    navigate(link)
    window.scrollTo(0, 0)
  }

  const showModal = () => {
    setIsModalOpen(true)
}
const handleOk = () => {
    setIsModalOpen(false);
};

const handleCancel = () => {
    setIsModalOpen(false);
};

  return (
    <>
      <Footer className='footer'>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} className='redes-sociales' >
            <a
              href='https://www.facebook.com/bolsadesantiago/'
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleExternalLink(e.currentTarget.href);
              }}
            >
              <img src={Face} alt='facebook' style={{ marginRight: 10, width: 30, height: 30 }} />
            </a>
            <a
              href='https://www.linkedin.com/company/35683183/'
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleExternalLink(e.currentTarget.href);
              }}
            >
              <img src={ind} alt='instagram' style={{ marginRight: 10, width: 30, height: 30 }} />
            </a>
            <a
              href='https://www.instagram.com/labolsadesantiago/'
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleExternalLink(e.currentTarget.href);
              }}
            >
              <img src={Insta} alt='twitter' style={{ width: 30, height: 30 }} />
            </a>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={4} className='footer-mobile' style={{ paddingLeft: 0, textAlign: 'left', marginRight: -72, marginLeft: -12 }}>
            <Button onClick={() => onRedirect('/home')} className="link-footer" type="link">{t('home')}</Button>
          </Col>
          <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={4} style={{ paddingLeft: 0, textAlign: 'left', marginRight: 0 }}>
            <Button onClick={() => onRedirect('/nuestras-apis')} className="link-footer" type="link">{t('our_apis')}</Button>
          </Col>
          <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={4} style={{ paddingLeft: 0, textAlign: 'left', marginRight: 10 }}>
            <Button onClick={() => onRedirect('/como-funciona')} className="link-footer" type="link">{t('how_does_it_work')}</Button>
          </Col>
          <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={4} style={{ paddingLeft: 0, textAlign: 'left', marginRight: -20 }}>
            <Button onClick={() => onRedirect('/contacto')} className="link-footer" type="link">{t('contact')}</Button>
          </Col>
          <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={4} style={{ paddingLeft: 0, textAlign: 'left' }}>
            <Button onClick={showModal} className="link-footer" type="link">{t('terms_conditions')}</Button>
          </Col>
        </Row>
      </Footer>
      <ModalTerminosCondiciones isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </>
  )
}

export default FooterLanding
