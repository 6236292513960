import React, { useState } from 'react';
import { Button, Card, Col, Layout, Row, Space, Spin, Typography, Flex, Carousel } from 'antd';
import LogoProducto from '../apis/LogoProducto';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { productosApi } from '../../services/producto';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

const { Content } = Layout;
const { Title } = Typography;
const { Text } = Typography;

const ContentCatalogo = ({ t, i18n }) => {
  const navigate = useNavigate();
  const language = useSelector(state => state.aplicacion.language);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [idiomaTraduccion, setIdiomaTraduccion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiProductos = await productosApi([1, 4])
        if (apiProductos) {
          setSelectedCategory(apiProductos)
        }
      } catch (error) {
        console.log(error)
      } finally {
      }
    }
    fetchData()
  }, [])


  useEffect(() => {
    if (language && selectedCategory && selectedCategory.length > 0) {
      const productosConTraduccionesTemp = [];

      for (const producto of selectedCategory) {
        const traduccion = producto.IdIdiomaTraducir.find(item => item.IdIdiomaTraducir === (language === 'es' ? 1 : 2));

        if (traduccion) {
          const productoConTraduccion = {
            ...producto,
            ...traduccion,
          };

          productosConTraduccionesTemp.push(productoConTraduccion);
        }
      }

      setIdiomaTraduccion(productosConTraduccionesTemp);
    }
  }, [selectedCategory, language]);

  const handleButton = (idProducto) => {
    navigate(`/detalle-producto/${idProducto}`, { state: { productId: idProducto } })
    window.scrollTo(0, 0)
  };


  return (
    <Content className='content-catalog'>
      <div className='content-catalogo'>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} style={{ marginTop: 80, paddingLeft: 5, textAlign: 'left' }} className='estilo-catalogo'>
            <Title level={2} style={{ color: '#FFC000' }}> {t('our_apis_title')}</Title>
            <div className='categorias'>
              <Text style={{ color: '#fff' }}>
                <span>{t('our_apis_description1')}</span>
                <br />
                <br />
                <span>{t('our_apis_description2')}</span>
              </Text>
            </div>
          </Col>
          <Col xs={0} sm={0} md={1}></Col>
          <Col xs={0} sm={0} md={17} style={{ marginTop: 80 }} >

            {idiomaTraduccion.length > 0 ? (

              <Flex wrap="wrap" gap="middle" justify="left">
                {idiomaTraduccion.map((cards, index) => (
                  <Card key={cards.IdProducto}
                    cover={<LogoProducto idTipoProducto={cards.IdTipoProducto}></LogoProducto>}
                    className='card-producto'
                    style={{ width: 255, height: 330, position: 'relative' }}
                  >
                    <div style={{ flex: 1 }}>
                      <div className='body-card-producto'>
                        <Title level={4} style={{ textDecoration: 'underline', textAlign: 'left', fontWeight: 'bold' }}>{cards.NombreProducto}</Title>
                        <p>
                          {parse(cards.DescripcionProducto)}
                        </p>

                        <p style={{ textAlign: 'left' }} >{cards.CodigoAgrupacion === "API-FREE" ? t('our_apis_card1_description_free') : null}</p>
                      </div>
                    </div>
                    <div style={{ position: 'absolute', bottom: 20, width: '80%' }}>
                      <Button type='primary' className='btn-catalogo' shape='round' onClick={() => handleButton(cards.IdProducto)}>{t('our_apis_cards_btn_details')}</Button>
                    </div>
                  </Card>
                ))}
              </Flex>

            ) : (<Spin size='large' style={{ color: '#fff' }} />)}

          </Col>
          <Col xs={24} sm={24} md={0} style={{ marginTop: 27 }} >
              <>
                {idiomaTraduccion.length > 0 ? (
                    <Carousel>
                      {idiomaTraduccion.map((cards, index) => (
                        <>
                        <Card
                        style={{ height: 400, borderRadius: 20, marginLeft: 10, marginRight: 10 }}
                        key={cards.IdProducto}
                        cover={<LogoProducto idTipoProducto={cards.IdTipoProducto}></LogoProducto>}
                        className='card-producto'
                        
                      >
                          <div style={{ flex: 1, marginBottom: 20 }}>
                              <div>
                                  <Title level={4} style={{ textDecoration: 'underline', textAlign: 'left', fontWeight: 'bold' }}>
                                    {cards.NombreProducto}
                                  </Title>
                                  <p>
                                  {parse(cards.DescripcionProducto)}
                                </p>
                                  <p style={{ textAlign: 'left' }} >{cards?.CodigoAgrupacion === "API-FREE" ? t('our_apis_card1_description_free') : null}</p>
                              </div>
                          </div>
                          <br />
                          <br />
                          <div style={{ position: 'absolute', bottom: 20, width: '80%', textAlign: 'center' }}>
                            <Button type='primary' className='btn-catalogo' shape='round' onClick={() => handleButton(cards.IdProducto)}>{t('our_apis_cards_btn_details')}</Button>
                          </div>
                      </Card>
                      </>
                      ))}
                   </Carousel>

                    ) : (<Spin size='large' style={{ color: '#fff' }} />)}
              </>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default ContentCatalogo;
