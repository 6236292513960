import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'app',
    initialState: {
        language: 'es',
        isMobile: false,
    },
    reducers: {
        setLanguage(state, action) {
            state.language = action.payload;
        },
        setIsMobile(state, action){
            state.isMobile = action.payload;
        }
    }
})

export const { setLanguage, setIsMobile } = appSlice.actions

export default appSlice.reducer