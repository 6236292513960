import Navbar from '../layout/Header';
import ContentContacto from './Content';
import FooterLanding from '../layout/Footer';
import { useTranslation } from 'react-i18next';

const Contacto = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className='catalogo imagen-mobile' id='contacto'>
      <Navbar/>
      <ContentContacto t={t} i18n={i18n}/>
      <FooterLanding/>
    </div>
  )
}

export default Contacto